import request from '../../core/request'
import { openNotification } from '../../utils/Notification'
import {
  fetchReportsRequest,
  fetchReportsSuccess,
  fetchReportsFailure,
  createReportRequest,
  createReportSuccess,
  createReportFailure,
  updateReportRequest,
  updateReportSuccess,
  updateReportFailure,
  deleteReportRequest,
  deleteReportSuccess,
  deleteReportFailure,
  searchReportsSuccess,
  searchReportsFailure,
  fetchStatusesRequest,
  fetchStatusesSuccess,
  fetchStatusesFailure,
  fetchStudentDetailsRequest,
  fetchStudentDetailsSuccess,
  fetchStudentDetailsFailure
} from './reports.actions'

export const fetchReports = (filters, downloadCSV = false) => {
  const adminId = localStorage.getItem('adminId')
  return (dispatch) => {
    dispatch(fetchReportsRequest());

    const filteredFilters = Object.fromEntries(
      // eslint-disable-next-line no-unused-vars
      Object.entries(filters).filter(([key, value]) => value && value !== 'all')
    );

    const queryParams = new URLSearchParams({ ...filteredFilters, download_csv: downloadCSV }).toString();

    return request({
      method: 'GET',
      url: `/attendance/filter?${queryParams}&admin_id=${adminId}`,
    })
      .then((response) => {
        if (downloadCSV) {
          const blob = new Blob([response.data], { type: 'text/csv;charset=utf-8;' });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'reports.csv');
          document.body.appendChild(link);
          link.click();
          link.remove();
          dispatch(fetchReportsSuccess([], true));
        } else {
          if (response?.data) {
            dispatch(fetchReportsSuccess(response.data, false));
          } else {
            dispatch(fetchReportsFailure('Fetching reports failed'));
            openNotification({
              type: 'error',
              title: 'Fetching Reports Failed',
              description: 'Fetching reports failed',
            });
          }
        }
      })
      .catch((error) => {
        if (error?.response?.status === 404) {
          dispatch(fetchReportsSuccess([], false));
        } else {
          dispatch(fetchReportsFailure('An error occurred while fetching reports'));
          openNotification({
            type: 'error',
            title: 'Fetching Reports Error',
            description: `An error occurred while fetching reports: ${error.message}`,
          });
        }
      });
  };
};

export const fetchStatuses = () => {
  return (dispatch) => {
    dispatch(fetchStatusesRequest())
    return request({
      method: 'GET',
      url: '/attendance/statuses',
    })
      .then((response) => {
        console.log("response", response)
        if (response?.data) {
          dispatch(fetchStatusesSuccess(response.data))
        } else {
          dispatch(fetchStatusesFailure('Fetching Statuses failed'))
          openNotification({
            type: 'error',
            title: 'Fetching Statuses Failed',
            description: 'Fetching Statuses failed',
          })
        }
      })
      .catch((error) => {
        if (error?.response?.status === 404) {
          dispatch(fetchStatusesSuccess([]))
        } else {
          dispatch(
            fetchStatusesFailure('An error occurred while fetching Statuses'),
          )
          openNotification({
            type: 'error',
            title: 'Fetching Statuses Error',
            description: `An error occurred while fetching Statuses: ${error.message}`,
          })
        }
      })
  }
}

export const fetchStudentDetails = (studentId) => {
  return (dispatch) => {
    dispatch(fetchStudentDetailsRequest())
    return request({
      method: 'GET',
      url: `/students/report/${studentId}`,
    })
      .then((response) => {
        console.log("response", response)
        if (response?.data) {
          dispatch(fetchStudentDetailsSuccess(response.data))
        } else {
          dispatch(fetchStudentDetailsFailure('Fetching student details failed'))
          openNotification({
            type: 'error',
            title: 'Fetching Student Details Failed',
            description: 'Fetching Student Details failed',
          })
        }
      })
      .catch((error) => {
        if (error?.response?.status === 404) {
          dispatch(fetchStudentDetailsSuccess([]))
        } else {
          dispatch(
            fetchStudentDetailsFailure('An error occurred while fetching Student Details'),
          )
          openNotification({
            type: 'error',
            title: 'Fetching Student Details Error',
            description: `An error occurred while fetching Student Details: ${error.message}`,
          })
        }
      })
  }
}

// Function to handle creating a report
export const createReport = (reportData) => {
  return (dispatch) => {
    dispatch(createReportRequest(reportData))
    return request({
      method: 'POST',
      url: '/reports',
      data: reportData,
    })
      .then((response) => {
        if (response?.data) {
          dispatch(createReportSuccess(response.data))
        } else {
          dispatch(createReportFailure('Creating report failed'))
          openNotification({
            type: 'error',
            title: 'Creating Report Failed',
            description: 'Creating report failed',
          })
        }
      })
      .catch((error) => {
        dispatch(
          createReportFailure('An error occurred while creating the report'),
        )
        openNotification({
          type: 'error',
          title: 'Creating Report Error',
          description: `An error occurred while creating the report: ${error.message}`,
        })
      })
  }
}

// Function to handle updating a report
export const updateReport = (reportData) => {
  return (dispatch) => {
    dispatch(updateReportRequest(reportData))
    return request({
      method: 'PUT',
      url: `/reports/${reportData.id}`,
      data: reportData,
    })
      .then((response) => {
        if (response?.data) {
          dispatch(updateReportSuccess(response.data))
        } else {
          dispatch(updateReportFailure('Updating report failed'))
          openNotification({
            type: 'error',
            title: 'Updating Report Failed',
            description: 'Updating report failed',
          })
        }
      })
      .catch((error) => {
        dispatch(
          updateReportFailure('An error occurred while updating the report'),
        )
        openNotification({
          type: 'error',
          title: 'Updating Report Error',
          description: `An error occurred while updating the report: ${error.message}`,
        })
      })
  }
}

// Function to handle deleting a report
export const deleteReport = (reportId) => {
  return (dispatch) => {
    dispatch(deleteReportRequest(reportId))
    return request({
      method: 'DELETE',
      url: `/reports/${reportId}`,
    })
      .then((response) => {
        if (response?.data) {
          dispatch(deleteReportSuccess(reportId))
        } else {
          dispatch(deleteReportFailure('Deleting report failed'))
          openNotification({
            type: 'error',
            title: 'Deleting Report Failed',
            description: 'Deleting report failed',
          })
        }
      })
      .catch((error) => {
        dispatch(
          deleteReportFailure('An error occurred while deleting the report'),
        )
        openNotification({
          type: 'error',
          title: 'Deleting Report Error',
          description: `An error occurred while deleting the report: ${error.message}`,
        })
      })
  }
}

// Function to handle searching reports
export const searchReports = (query) => {
  return (dispatch) => {
    return request({
      method: 'GET',
      url: `/reports/search?query=${query}`,
    })
      .then((response) => {
        if (response?.data) {
          dispatch(searchReportsSuccess(response.data))
        } else {
          dispatch(searchReportsFailure('Searching report failed'))
          openNotification({
            type: 'error',
            title: 'Searching Reports Failed',
            description: 'Searching reports failed',
          })
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          // Handle 404 (not found) error
          dispatch(searchReportsFailure('Searching report failed'))
        } else {
          openNotification({
            type: 'error',
            title: 'Searching Reports Error',
            description: `An error occurred while searching reports: ${error.message}`,
          })
        }
      })
  }
}
