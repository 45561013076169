import request from '../../core/request';
import { openNotification } from '../../utils/Notification';
import {
  fetchAdminsRequest,
  fetchAdminsSuccess,
  fetchAdminsFailure,
  createAdminRequest,
  createAdminSuccess,
  createAdminFailure,
  updateAdminRequest,
  updateAdminSuccess,
  updateAdminFailure,
  deleteAdminRequest,
  deleteAdminSuccess,
  deleteAdminFailure,
} from './admins.actions';

// Function to handle fetching admins
export const fetchAdmins = () => {
  return (dispatch) => {
    dispatch(fetchAdminsRequest());
    return request({
      method: 'GET',
      url: '/admins/',
    })
      .then((response) => {
        if (response?.data) {
          dispatch(fetchAdminsSuccess(response.data));
        } else if (response?.status === 404 || response?.status === 204) {
          dispatch(fetchAdminsSuccess([]));
        } else {
          dispatch(fetchAdminsFailure('Fetching admins failed'));
          openNotification({
            type: 'error',
            title: 'Fetching Admins Failed',
            description: 'Fetching admins failed',
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 404) {
          dispatch(fetchAdminsSuccess([]));
        } else {
          dispatch(
            fetchAdminsFailure('An error occurred while fetching admins'),
          );
          openNotification({
            type: 'error',
            title: 'Fetching Admins Error',
            description: `An error occurred while fetching admins: ${error.message}`,
          });
        }
      });
  };
};

// Function to handle creating an admin
export const createAdmin = (adminData) => {
  return (dispatch) => {
    dispatch(createAdminRequest(adminData));
    return request({
      method: 'POST',
      url: '/admins/',
      data: adminData,
    })
      .then((response) => {
        if (response?.data) {
          dispatch(createAdminSuccess(response.data));
        } else {
          dispatch(createAdminFailure('Creating admin failed'));
          openNotification({
            type: 'error',
            title: 'Creating Admin Failed',
            description: 'Creating admin failed',
          });
        }
      })
      .catch((error) => {
        dispatch(
          createAdminFailure('An error occurred while creating the admin'),
        );
        openNotification({
          type: 'error',
          title: 'Creating Admin Error',
          description: `An error occurred while creating the admin: ${error.message}`,
        });
      });
  };
};

// Function to handle updating an admin
export const updateAdmin = (adminId, adminData) => {
  return (dispatch) => {
    dispatch(updateAdminRequest(adminData));
    return request({
      method: 'PUT',
      url: `/admins/${adminId}`,
      data: adminData,
    })
      .then((response) => {
        if (response?.data) {
          dispatch(updateAdminSuccess(response.data));
        } else {
          dispatch(updateAdminFailure('Updating admin failed'));
          openNotification({
            type: 'error',
            title: 'Updating Admin Failed',
            description: 'Updating admin failed',
          });
        }
      })
      .catch((error) => {
        dispatch(
          updateAdminFailure('An error occurred while updating the admin'),
        );
        openNotification({
          type: 'error',
          title: 'Updating Admin Error',
          description: `An error occurred while updating the admin: ${error.message}`,
        });
      });
  };
};

// Function to handle deleting an admin
export const deleteAdmin = (adminId) => {
  return (dispatch) => {
    dispatch(deleteAdminRequest(adminId));
    return request({
      method: 'DELETE',
      url: `/admins/${adminId}?deletion_type=hard`,
    })
      .then((response) => {
        if (response?.data) {
          dispatch(deleteAdminSuccess(adminId));
        } else {
          dispatch(deleteAdminFailure('Deleting admin failed'));
          openNotification({
            type: 'error',
            title: 'Deleting Admin Failed',
            description: 'Deleting admin failed',
          });
        }
      })
      .catch((error) => {
        dispatch(
          deleteAdminFailure('An error occurred while deleting the admin'),
        );
        openNotification({
          type: 'error',
          title: 'Deleting Admin Error',
          description: `An error occurred while deleting the admin: ${error.message}`,
        });
      });
  };
};
