import { connect } from 'react-redux'
import {
  fetchSessions,
  createSession,
  updateSession,
  deleteSession,
} from './sessions.api'
import AdminSessions from './AdminSessions'

const mapStateToProps = (state) => ({
  sessions: state.sessions.sessions,
  loading: state.sessions.loading,
  error: state.sessions.error,
})

const mapDispatchToProps = (dispatch) => ({
  fetchSessions: () => dispatch(fetchSessions()),
  createSession: (sessionData) => dispatch(createSession(sessionData)),
  updateSession: (sessionData) => dispatch(updateSession(sessionData)),
  deleteSession: (sessionId) => dispatch(deleteSession(sessionId)),
})

export const SessionsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminSessions)
