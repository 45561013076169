import {
  FETCH_SESSIONS_REQUEST,
  FETCH_SESSIONS_SUCCESS,
  FETCH_SESSIONS_FAILURE,
  CREATE_SESSION_REQUEST,
  CREATE_SESSION_SUCCESS,
  CREATE_SESSION_FAILURE,
  UPDATE_SESSION_REQUEST,
  UPDATE_SESSION_SUCCESS,
  UPDATE_SESSION_FAILURE,
  DELETE_SESSION_REQUEST,
  DELETE_SESSION_SUCCESS,
  DELETE_SESSION_FAILURE,
  SEARCH_SESSIONS_FAILURE,
  SEARCH_SESSIONS_SUCCESS,
  SEARCH_SESSIONS_REQUEST,
  FETCH_AVAILABLE_PCS_REQUEST,
  FETCH_AVAILABLE_PCS_SUCCESS,
  FETCH_AVAILABLE_PCS_FAILURE,
} from './sessions.actions';

const initialState = {
  sessions: [],
  loading: false,
  error: null,
  availablePCs: [],
};

const sessionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SESSIONS_REQUEST:
    case SEARCH_SESSIONS_REQUEST:
    case CREATE_SESSION_REQUEST:
    case UPDATE_SESSION_REQUEST:
    case DELETE_SESSION_REQUEST:
    case FETCH_AVAILABLE_PCS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_SESSIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        sessions: action.payload,
        error: null,
      };
    case CREATE_SESSION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        sessions: [...state.sessions, action.payload],
      };
    case UPDATE_SESSION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        sessions: state.sessions.map((session) =>
          session.id === action.payload.id ? action.payload : session,
        ),
      };
    case DELETE_SESSION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        sessions: state.sessions.filter(
          (session) => session.id !== action.payload.id,
        ),
      };
    case SEARCH_SESSIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        searchedSessions: action.payload,
        error: null,
      };
    case FETCH_AVAILABLE_PCS_SUCCESS:
      return {
        ...state,
        loading: false,
        availablePCs: action.payload,
        error: null,
      };
    case SEARCH_SESSIONS_FAILURE:
    case FETCH_SESSIONS_FAILURE:
    case CREATE_SESSION_FAILURE:
    case UPDATE_SESSION_FAILURE:
    case DELETE_SESSION_FAILURE:
    case FETCH_AVAILABLE_PCS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default sessionsReducer;
