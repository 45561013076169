import React from 'react';
import { Menu } from 'antd';

// eslint-disable-next-line react/prop-types
const EventContextMenu = ({ position, visible, onEdit, onDelete, onArchive, onViewAttendance }) => {
  if (!visible) {
    return null;
  }

  const menuStyle = {
    position: 'absolute',
    // eslint-disable-next-line react/prop-types
    top: `${position.y}px`,
    // eslint-disable-next-line react/prop-types
    left: `${position.x}px`,
    zIndex: 1000,
    backgroundColor: 'white',
    border: '1px solid #ccc',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
  };

  return (
    <div style={menuStyle}>
      <Menu>
        <Menu.Item key="edit" onClick={onEdit}>
          Edit
        </Menu.Item>
        <Menu.Item key="archive" onClick={onArchive}>
          Archive
        </Menu.Item>
        <Menu.Item key="delete" onClick={onDelete}>
          Delete
        </Menu.Item>
        <Menu.Item key="view" onClick={onViewAttendance}>
          View Attendance
        </Menu.Item>
      </Menu>
    </div>
  );
};

export default EventContextMenu;
