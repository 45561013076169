import { combineReducers } from 'redux'

// Import your reducers here
import dashboard from '../components/Dashboard/dashboard.reducer'
import auth from '../components/Auth/auth.reducer'
import students from '../components/Students/students.reducer'
import campuses from '../components/Campuses/campuses.reducer'
import courses from '../components/Courses/courses.reducer'
import sessions from '../components/Sessions/sessions.reducer'
import attendances from '../components/Attendance/attendance.reducer'
import reports from '../components/Reports/reports.reducer'
import admins from '../components/Admins/admins.reducer'

const rootReducer = combineReducers({
  dashboard,
  auth,
  students,
  campuses,
  courses,
  sessions,
  attendances,
  reports,
  admins,
})

export default rootReducer
