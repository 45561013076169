import React, { useEffect, useState } from 'react';
import {
  Card,
  Row,
  Col,
  Select,
  Spin,
  Alert,
  Button,
  Modal,
  Tooltip,
  Empty,
  Form,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCourses, createCourse, updateCourse, deleteCourse } from './courses.api';
import AddCourseForm from './Form/AddCourseForm';

const { Meta } = Card;
const { Option } = Select;

const truncateText = (text, length) => {
  return text?.length > length ? `${text.substring(0, length)}...` : text;
};

const CourseCard = () => {
  const [coursesToShow, setCoursesToShow] = useState(6); // Default number of courses to show
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [currentCourse, setCurrentCourse] = useState(null);
  const dispatch = useDispatch();
  const { courses, loading, error } = useSelector((state) => state.courses);

  // Function to generate a random gradient
  const generateRandomGradient = () => {
    const randomAngle = Math.floor(Math.random() * 360);
    const color1 = '#' + Math.floor(Math.random() * 16777215).toString(16); // Random hex color
    const color2 = '#' + Math.floor(Math.random() * 16777215).toString(16); // Random hex color
    return `linear-gradient(${randomAngle}deg, ${color1}, ${color2})`;
  };

  useEffect(() => {
    const adminId = localStorage.getItem('admin');
    dispatch(fetchCourses(adminId));
  }, [dispatch]);

  const handleSelectChange = (value) => {
    setCoursesToShow(value);
  };

  const handleAddOrEditCourse = (values) => {
    if (currentCourse) {
      dispatch(updateCourse({ ...values, id: currentCourse.id })).then(() => {
        setIsModalVisible(false);
        form.resetFields();
        setCurrentCourse(null);
        dispatch(fetchCourses());
      });
    } else {
      dispatch(createCourse(values)).then(() => {
        setIsModalVisible(false);
        form.resetFields();
        dispatch(fetchCourses());
      });
    }
  };

  const handleDeleteCourse = (courseId) => {
    dispatch(deleteCourse(courseId)).then(() => {
      dispatch(fetchCourses());
    });
  };

  const handleEditClick = (course) => {
    setCurrentCourse(course);
    form.setFieldsValue({
      title: course.title,
      campus_id: course.campusId,
    });
    setIsModalVisible(true);
  };

  if (loading) {
    return <Spin tip="Loading courses..." />;
  }

  if (error) {
    return <Alert message="Error" description={error} type="error" showIcon />;
  }

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
        <h2 style={{ margin: 0 }}>Courses</h2>
        <p style={{ marginLeft: 'auto' }}>
          Show &nbsp;
          <Select defaultValue={coursesToShow.toString()} onChange={handleSelectChange}>
            <Option value="3">3</Option>
            <Option value="6">6</Option>
            <Option value="9">9</Option>
            <Option value="14">14</Option>
            <Option value="20">20</Option>
            <Option value="30">30</Option>
          </Select>
          &nbsp;
          <Button type="primary" onClick={() => {
            setCurrentCourse(null);
            form.resetFields();
            setIsModalVisible(true);
          }}>
            Add Course
          </Button>
        </p>
      </div>
      {courses.length === 0 ? (
        <Empty description="No courses available" />
      ) : (
        <Row gutter={[16, 16]}>
          {courses.slice(0, coursesToShow).map((course) => (
            <Col key={course.id} span={8}>
              <Card
                hoverable
                style={{ width: '100%', borderRadius: 20, marginBottom: '20px' }}
                cover={
                  <div
                    style={{
                      background: generateRandomGradient(),
                      height: '160px',
                      borderTopRightRadius: 20,
                      borderTopLeftRadius: 20,
                      backgroundSize: 'cover',
                    }}
                  ></div>
                }
                actions={[
                  <Button key="edit" type="link" onClick={() => handleEditClick(course)}>
                    Edit
                  </Button>,
                  <Button
                    key={'delete'}
                    type="link"
                    danger
                    onClick={() => handleDeleteCourse(course.id)}
                  >
                    Delete
                  </Button>,
                ]}
              >
                <div style={{ minHeight: '100px' }}>
                  <Meta
                    title={
                      <Tooltip title={course.title}>
                        {truncateText(course?.title, 20)}
                      </Tooltip>
                    }
                  />
                  <Meta
                    description={
                      <Tooltip title={course.campusName}>
                        <b>Campus Name:</b> {truncateText(course?.campusName, 20)}
                      </Tooltip>
                    }
                  />
                </div>
              </Card>
            </Col>
          ))}
        </Row>
      )}
      <Modal
        title={currentCourse ? "Edit Course" : "Add New Course"}
        visible={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false);
          setCurrentCourse(null);
          form.resetFields();
        }}
        footer={null}
      >
        <AddCourseForm form={form} onSubmit={handleAddOrEditCourse} />
      </Modal>
    </>
  );
};

export default CourseCard;
