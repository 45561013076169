import {
  FETCH_REPORTS_REQUEST,
  FETCH_REPORTS_SUCCESS,
  FETCH_REPORTS_FAILURE,
  CREATE_REPORT_REQUEST,
  CREATE_REPORT_SUCCESS,
  CREATE_REPORT_FAILURE,
  UPDATE_REPORT_REQUEST,
  UPDATE_REPORT_SUCCESS,
  UPDATE_REPORT_FAILURE,
  DELETE_REPORT_REQUEST,
  DELETE_REPORT_SUCCESS,
  DELETE_REPORT_FAILURE,
  SEARCH_REPORTS_FAILURE,
  SEARCH_REPORTS_SUCCESS,
  FETCH_STATUSES_REQUEST,
  FETCH_STATUSES_SUCCESS,
  FETCH_STATUSES_FAILURE,
  FETCH_STUDENT_DETAILS_REQUEST,
  FETCH_STUDENT_DETAILS_SUCCESS,
  FETCH_STUDENT_DETAILS_FAILURE
} from './reports.actions'

const initialState = {
  reports: [],
  loading: false,
  error: null,
  statuses: [],
  studentDetails: null,
  studentDetailsLoading: false,
  studentDetailsError: null
}

const reportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_REPORTS_REQUEST:
    case FETCH_STATUSES_REQUEST:
    case CREATE_REPORT_REQUEST:
    case UPDATE_REPORT_REQUEST:
    case DELETE_REPORT_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case FETCH_REPORTS_SUCCESS:
      if(action?.payload?.csv){
        return {
          ...state,
          loading: false,
          error: null,
        }
      }
      return {
        ...state,
        loading: false,
        reports: action.payload.reports,
        error: null,
      }
    case FETCH_STATUSES_SUCCESS:
      return {
        ...state,
        loading: false,
        statuses: action.payload,
        error: null,
      }
    case CREATE_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        reports: [...state.reports, action.payload],
      }
    case UPDATE_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        reports: state.reports.map((report) =>
          report.id === action.payload.id ? action.payload : report,
        ),
      }
    case DELETE_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        reports: state.reports.filter(
          (report) => report.id !== action.payload,
        ),
      }
    case SEARCH_REPORTS_SUCCESS:
      return {
        ...state,
        loading: false,
        searchedReports: action.payload,
        error: null,
      }
    case FETCH_STUDENT_DETAILS_SUCCESS:
      return {
        ...state,
        studentDetailsLoading: false,
        studentDetails: action.payload,
        studentDetailsError: null
      }
    case SEARCH_REPORTS_FAILURE:
    case FETCH_REPORTS_FAILURE:
    case CREATE_REPORT_FAILURE:
    case UPDATE_REPORT_FAILURE:
    case FETCH_STATUSES_FAILURE:
    case DELETE_REPORT_FAILURE:
    case FETCH_STUDENT_DETAILS_FAILURE:
      return {
        ...state,
        loading: action.type !== FETCH_STUDENT_DETAILS_FAILURE,
        studentDetailsLoading: action.type === FETCH_STUDENT_DETAILS_FAILURE,
        error: action.type !== FETCH_STUDENT_DETAILS_FAILURE ? action.payload : state.error,
        studentDetailsError: action.type === FETCH_STUDENT_DETAILS_FAILURE ? action.payload : state.studentDetailsError,
        searchedReports: action.type === SEARCH_REPORTS_FAILURE ? [] : state.searchedReports,
        statuses: action.type === FETCH_STATUSES_FAILURE ? [] : state.statuses,
      }
    case FETCH_STUDENT_DETAILS_REQUEST:
      return {
        ...state,
        studentDetailsLoading: action.type === FETCH_STUDENT_DETAILS_REQUEST
      }
    default:
      return state
  }
}

export default reportsReducer
