// request.js
import axios from 'axios'
import { get } from 'lodash'
import sanitizeHtml from 'sanitize-html'
import transformKeys from './transformKeys'
import { MIME_TYPES } from './constants'

const BASE_URL = 'https://ctpattendance.co.uk/v1'

// Function to get headers based on configuration and refresh status
const getHeaders = (config) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...config.headers,
  }

  // Check if the request is for file upload
  if (get(config, 'file') || config?.isFormData) {
    headers['Content-Type'] = 'multipart/form-data'
  }

  // Retrieve token from local storage
  const token = localStorage.getItem('token')

  if (token) {
    // Remove "Bearer" prefix from token if present
    const tokenWithoutBearer = token.replace('Bearer ', '')
    headers.Authorization = `Bearer ${tokenWithoutBearer}`
  }

  return {
    ...config,
    headers,
  }
}

// Function to get options based on configuration and refresh status
const getOptions = (config, refresh) => {
  return getHeaders(config, refresh)
}

// Function to make HTTP request
const request = (config, refresh) => {
  let options = getOptions(config, refresh)
  options.baseURL = BASE_URL

  // Sanitize data and params if required
  if (options?.data || options?.params) {
    if (
      options.headers['Content-Type'] !== 'multipart/form-data' &&
      options?.sanitize !== false
    ) {
      options.data &&
      (options.data = JSON.parse(sanitizeHtml(JSON.stringify(options.data))))
      options.params &&
      (options.params = JSON.parse(
        sanitizeHtml(JSON.stringify(options.params)),
      ))
    }
  }

  // Make the HTTP request using axios
  return axios(options)
    .then((response) => {
      // Transform response data if needed
      if (options?.headers['Content-Type'] !== MIME_TYPES.json) {
        return response
      }
      return transformKeys.toCamelCase(JSON.parse(JSON.stringify(response)))
    })
    .catch((error) => {
      // Handle errors
      return Promise.reject(error)
    })
}

export default request
