import request from '../../core/request'
import { openNotification } from '../../utils/Notification'
import {
  fetchSessionsRequest,
  fetchSessionsSuccess,
  fetchSessionsFailure,
  createSessionRequest,
  createSessionSuccess,
  createSessionFailure,
  updateSessionRequest,
  updateSessionSuccess,
  updateSessionFailure,
  deleteSessionRequest,
  deleteSessionSuccess,
  deleteSessionFailure,
  fetchAvailablePCsRequest,
  fetchAvailablePCsSuccess,
  fetchAvailablePCsFailure,
  searchSessionsRequest,
  searchSessionsSuccess,
  searchSessionsFailure,
} from './sessions.actions'

// Function to handle fetching sessions
export const fetchSessions = () => {
  const adminId = localStorage.getItem('adminId')
  return (dispatch) => {
    dispatch(fetchSessionsRequest())
    return request({
      method: 'GET',
      url: `/sessions/?admin_id=${adminId}`,
    })
      .then((response) => {
        if (response?.data) {
          dispatch(fetchSessionsSuccess(response.data))
        }
        else if (response?.status === 404 || response?.status === 204) {
          dispatch(fetchSessionsSuccess([]))
        }
        else {
          dispatch(fetchSessionsFailure('Fetching sessions failed'))
          openNotification({
            type: 'error',
            title: 'Fetching Sessions Failed',
            description: 'Fetching sessions failed',
          })
        }
      })
      .catch((error) => {
        if(error?.response?.status === 404){
          dispatch(fetchSessionsSuccess([]))
        }
        else {
          dispatch(
            fetchSessionsFailure('An error occurred while fetching sessions'),
          )
          openNotification({
            type: 'error',
            title: 'Fetching Sessions Error',
            description: `An error occurred while fetching sessions: ${error.message}`,
          })
        }
      })
  }
}

export const fetchAvailablePCs = (campusId, startDatetime, endDatetime) => {
  return (dispatch) => {
    dispatch(fetchAvailablePCsRequest());
    return request({
      method: 'GET',
      url: '/pcs/',
      params: {
        campus_id: campusId,
        start_datetime: startDatetime,
        end_datetime: endDatetime,
        available: true,
      },
    })
      .then((response) => {
        if (response?.data) {
          dispatch(fetchAvailablePCsSuccess(response.data));
        } else if (response?.status === 404 || response?.status === 204) {
          dispatch(fetchAvailablePCsSuccess([]));
        } else {
          dispatch(fetchAvailablePCsFailure('Fetching available PCs failed'));
          openNotification({
            type: 'error',
            title: 'Fetching Available PCs Failed',
            description: 'Fetching available PCs failed',
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 404) {
          dispatch(fetchAvailablePCsSuccess([]));
        } else {
          dispatch(fetchAvailablePCsFailure('An error occurred while fetching available PCs'));
          openNotification({
            type: 'error',
            title: 'Fetching Available PCs Error',
            description: `An error occurred while fetching available PCs: ${error.message}`,
          });
        }
      });
  };
};

export const fetchStudentSessions = () => {
  return (dispatch) => {
    dispatch(fetchSessionsRequest())
    const studentId = localStorage.getItem('studentId')
    return request({
      method: 'GET',
      url: `/sessions/?student_id=${studentId}`,
    })
      .then((response) => {
        console.log("response", response)
        if (response?.data) {
          dispatch(fetchSessionsSuccess(response.data))
        }  else if (response?.status === 404 || response?.status === 204) {
          dispatch(fetchSessionsSuccess([]));
        } else {
          dispatch(fetchSessionsFailure('Fetching sessions failed'))
          openNotification({
            type: 'error',
            title: 'Fetching Sessions Failed',
            description: 'Fetching sessions failed',
          })
        }
      })
      .catch((error) => {
        if(error?.response?.status === 404){
          dispatch(fetchSessionsSuccess([]))
        }
        else {
          dispatch(
            fetchSessionsFailure('An error occurred while fetching sessions'),
          )
          openNotification({
            type: 'error',
            title: 'Fetching Sessions Error',
            description: `An error occurred while fetching sessions: ${error.message}`,
          })
        }
      })
  }
}

// Function to handle creating a session
export const createSession = (sessionData) => {
  return (dispatch) => {
    dispatch(createSessionRequest(sessionData))
    return request({
      method: 'POST',
      url: '/sessions/',
      data: sessionData,
    })
      .then((response) => {
        if (response?.data) {
          dispatch(createSessionSuccess(response.data))
        } else {
          dispatch(createSessionFailure('Creating session failed'))
          openNotification({
            type: 'error',
            title: 'Creating Session Failed',
            description: 'Creating session failed',
          })
        }
      })
      .catch((error) => {
        dispatch(
          createSessionFailure('An error occurred while creating the session'),
        )
        openNotification({
          type: 'error',
          title: 'Creating Session Error',
          description: `An error occurred while creating the session: ${error.message}`,
        })
      })
  }
}

// Function to handle updating a session
export const updateSession = (sessionData) => {
  return (dispatch) => {
    dispatch(updateSessionRequest(sessionData))
    return request({
      method: 'PUT',
      url: `/sessions/${sessionData.id}`,
      data: sessionData,
    })
      .then((response) => {
        if (response?.data) {
          dispatch(updateSessionSuccess(response.data))
        } else {
          dispatch(updateSessionFailure('Updating session failed'))
          openNotification({
            type: 'error',
            title: 'Updating Session Failed',
            description: 'Updating session failed',
          })
        }
      })
      .catch((error) => {
        dispatch(
          updateSessionFailure('An error occurred while updating the session'),
        )
        openNotification({
          type: 'error',
          title: 'Updating Session Error',
          description: `An error occurred while updating the session: ${error.message}`,
        })
      })
  }
}

// Function to handle deleting a session
export const deleteSession = (data) => {
  return (dispatch) => {
    dispatch(deleteSessionRequest(data.id))
    return request({
      method: 'DELETE',
      url: `/sessions/${data.id}?deletion_type=${data.deletionType}`,
    })
      .then((response) => {
        if (response?.data) {
          dispatch(deleteSessionSuccess(data.id))
        } else {
          dispatch(deleteSessionFailure('Deleting session failed'))
          openNotification({
            type: 'error',
            title: 'Deleting Session Failed',
            description: 'Deleting session failed',
          })
        }
      })
      .catch((error) => {
        dispatch(
          deleteSessionFailure('An error occurred while deleting the session'),
        )
        openNotification({
          type: 'error',
          title: 'Deleting Session Error',
          description: `An error occurred while deleting the session: ${error.message}`,
        })
      })
  }
}

// Function to handle searching sessions
export const searchSessions = (query) => {
  return (dispatch) => {
    dispatch(searchSessionsRequest());
    return request({
      method: 'GET',
      url: `/sessions/?name=${query}`,
    })
      .then((response) => {
        if (response?.data) {
          dispatch(searchSessionsSuccess(response.data));
        } else {
          dispatch(searchSessionsFailure('No sessions found matching your criteria'));
        }
      })
      .catch((error) => {
        dispatch(searchSessionsFailure('An error occurred while searching for sessions', error));
      });
  };
};
