import {
  FETCH_ADMINS_REQUEST,
  FETCH_ADMINS_SUCCESS,
  FETCH_ADMINS_FAILURE,
  CREATE_ADMIN_REQUEST,
  CREATE_ADMIN_SUCCESS,
  CREATE_ADMIN_FAILURE,
  UPDATE_ADMIN_REQUEST,
  UPDATE_ADMIN_SUCCESS,
  UPDATE_ADMIN_FAILURE,
  DELETE_ADMIN_REQUEST,
  DELETE_ADMIN_SUCCESS,
  DELETE_ADMIN_FAILURE,
  SEARCH_ADMINS_FAILURE,
  SEARCH_ADMINS_SUCCESS,
} from './admins.actions';

const initialState = {
  admins: [],
  loading: false,
  error: null,
};

const adminsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ADMINS_REQUEST:
    case CREATE_ADMIN_REQUEST:
    case UPDATE_ADMIN_REQUEST:
    case DELETE_ADMIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ADMINS_SUCCESS:
      return {
        ...state,
        loading: false,
        admins: action.payload,
        error: null,
      };
    case CREATE_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        admins: [...state.admins, action.payload],
      };
    case UPDATE_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        admins: state.admins.map((admin) =>
          admin.id === action.payload.id ? action.payload : admin,
        ),
      };
    case DELETE_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        admins: state.admins.filter(
          (admin) => admin.id !== action.payload.id,
        ),
      };
    case SEARCH_ADMINS_SUCCESS:
      return {
        ...state,
        loading: false,
        searchedAdmins: action.payload,
        error: null,
      };
    case SEARCH_ADMINS_FAILURE:
    case FETCH_ADMINS_FAILURE:
    case CREATE_ADMIN_FAILURE:
    case UPDATE_ADMIN_FAILURE:
    case DELETE_ADMIN_FAILURE:
      return {
        ...state,
        loading: false,
        searchedAdmins: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export default adminsReducer;
