import {
  FETCH_ATTENDANCES_REQUEST,
  FETCH_ATTENDANCES_SUCCESS,
  FETCH_ATTENDANCES_FAILURE,
  CREATE_ATTENDANCE_REQUEST,
  CREATE_ATTENDANCE_SUCCESS,
  CREATE_ATTENDANCE_FAILURE,
  UPDATE_ATTENDANCE_REQUEST,
  UPDATE_ATTENDANCE_SUCCESS,
  UPDATE_ATTENDANCE_FAILURE,
  DELETE_ATTENDANCE_REQUEST,
  DELETE_ATTENDANCE_SUCCESS,
  DELETE_ATTENDANCE_FAILURE,
  SEARCH_ATTENDANCES_FAILURE,
  SEARCH_ATTENDANCES_SUCCESS,
} from './attendance.actions'

const initialState = {
  attendances: [],
  loading: false,
  error: null,
}

const attendanceReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ATTENDANCES_REQUEST:
    case CREATE_ATTENDANCE_REQUEST:
    case UPDATE_ATTENDANCE_REQUEST:
    case DELETE_ATTENDANCE_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case FETCH_ATTENDANCES_SUCCESS:
      return {
        ...state,
        loading: false,
        attendances: action.payload,
        error: null,
      }
    case CREATE_ATTENDANCE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        attendances: [...state.attendances, action.payload],
      }
    case UPDATE_ATTENDANCE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        attendances: state.attendances.map((attendance) =>
          attendance.id === action.payload.id ? action.payload : attendance,
        ),
      }
    case DELETE_ATTENDANCE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        attendances: state.attendances.filter(
          (attendance) => attendance.id !== action.payload.id,
        ),
      }
    case SEARCH_ATTENDANCES_SUCCESS:
      return {
        ...state,
        loading: false,
        searchedAttendances: action.payload,
        error: null,
      }
    case SEARCH_ATTENDANCES_FAILURE:
    case FETCH_ATTENDANCES_FAILURE:
    case CREATE_ATTENDANCE_FAILURE:
    case UPDATE_ATTENDANCE_FAILURE:
    case DELETE_ATTENDANCE_FAILURE:
      return {
        ...state,
        loading: false,
        searchedAttendances: [],
        error: action.payload,
      }
    default:
      return state
  }
}

export default attendanceReducer
