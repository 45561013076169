// Action Creators
export const FETCH_COURSES_REQUEST = 'FETCH_COURSES_REQUEST';
export const FETCH_COURSES_SUCCESS = 'FETCH_COURSES_SUCCESS';
export const FETCH_COURSES_FAILURE = 'FETCH_COURSES_FAILURE';

export const CREATE_COURSE_REQUEST = 'CREATE_COURSE_REQUEST';
export const CREATE_COURSE_SUCCESS = 'CREATE_COURSE_SUCCESS';
export const CREATE_COURSE_FAILURE = 'CREATE_COURSE_FAILURE';

export const UPDATE_COURSE_REQUEST = 'UPDATE_COURSE_REQUEST';
export const UPDATE_COURSE_SUCCESS = 'UPDATE_COURSE_SUCCESS';
export const UPDATE_COURSE_FAILURE = 'UPDATE_COURSE_FAILURE';

export const DELETE_COURSE_REQUEST = 'DELETE_COURSE_REQUEST';
export const DELETE_COURSE_SUCCESS = 'DELETE_COURSE_SUCCESS';
export const DELETE_COURSE_FAILURE = 'DELETE_COURSE_FAILURE';

export const fetchCoursesRequest = () => ({
  type: FETCH_COURSES_REQUEST,
});

export const fetchCoursesSuccess = (courses) => ({
  type: FETCH_COURSES_SUCCESS,
  payload: courses,
});

export const fetchCoursesFailure = (error) => ({
  type: FETCH_COURSES_FAILURE,
  payload: error,
});

export const createCourseRequest = (course) => ({
  type: CREATE_COURSE_REQUEST,
  payload: course,
});

export const createCourseSuccess = (course) => ({
  type: CREATE_COURSE_SUCCESS,
  payload: course,
});

export const createCourseFailure = (error) => ({
  type: CREATE_COURSE_FAILURE,
  payload: error,
});

export const updateCourseRequest = (course) => ({
  type: UPDATE_COURSE_REQUEST,
  payload: course,
});

export const updateCourseSuccess = (course) => ({
  type: UPDATE_COURSE_SUCCESS,
  payload: course,
});

export const updateCourseFailure = (error) => ({
  type: UPDATE_COURSE_FAILURE,
  payload: error,
});

export const deleteCourseRequest = (courseId) => ({
  type: DELETE_COURSE_REQUEST,
  payload: courseId,
});

export const deleteCourseSuccess = (courseId) => ({
  type: DELETE_COURSE_SUCCESS,
  payload: courseId,
});

export const deleteCourseFailure = (error) => ({
  type: DELETE_COURSE_FAILURE,
  payload: error,
});
