import { connect } from 'react-redux'
import Reports from './Reports'
import {
  fetchReports,
  createReport,
  updateReport,
  deleteReport,
} from './reports.api'

const mapStateToProps = (state) => ({
  reports: state.reports.reports,
  loading: state.reports.loading,
  error: state.reports.error,
})

const mapDispatchToProps = (dispatch) => ({
  fetchReports: () => dispatch(fetchReports()),
  createReport: (reportData) => dispatch(createReport(reportData)),
  updateReport: (reportData) => dispatch(updateReport(reportData)),
  deleteReport: (reportId) => dispatch(deleteReport(reportId)),
})

export const ReportsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Reports)
