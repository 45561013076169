import { connect } from 'react-redux'
import Dashboard from './Dashboard.view'
import { fetchDashboardStats } from './dashboard.api'

const mapStateToProps = (state) => ({
  counter: state.dashboard.counter,
})

const mapDispatchToProps = (dispatch) => ({
  fetchDashboardStats: (date) => {
    dispatch(fetchDashboardStats(date))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
