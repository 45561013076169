import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Typography } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import leftImageLogin from '../../../assets/images/leftImageLogin.jpg'
import ctpLogo from '../../../assets/images/ctplogo.png'

const { Title } = Typography;

// eslint-disable-next-line react/prop-types
const Login = ({ login }) => {
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState(null);

  const onFinish = (values) => {
    setFormValues(values);
  };

  useEffect(() => {
    if (formValues) {
      login(formValues)
        .then((response) => {
          if (response?.data.student) {
            navigate(`/sessions/${response?.data?.student.id}`);
          } else {
            navigate('/dashboard');
          }
        })
        .catch((error) => {
          console.error('Login failed:', error);
        });
    }
  }, [formValues, login, navigate]);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        height: 800,
        alignItems: 'center',
        backgroundImage: 'linear-gradient(to right, #004990, #007dff)',
        backgroundSize: 'cover',
        borderRadius: 30,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <div
        style={{
          display: 'flex',
          width: '100%',
          maxWidth: '1200px',
          backgroundColor: '#fff',
          borderRadius: 50,
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          overflow: 'hidden',
        }}
      >
        <div style={{ flex: 1, background: 'white' }}>
          <img
            src={leftImageLogin}
            alt="Left Illustration"
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          />
        </div>
        <div style={{ width: '2px', backgroundColor: '#e0e0e0' }} />
        <div style={{ flex: 1, padding: '80px 40px' }}>
          <div style={{ marginBottom: 30 }}>
            <img
              src={ctpLogo}
              alt="Focus Logo"
              style={{ width: 80, height: 80 }}
            />
            <Title level={3} style={{ margin: '20px 0' }}>
              Welcome Back
            </Title>
            <p style={{ color: '#888' }}>Login to your account</p>
          </div>
          <Form name="login_form" onFinish={onFinish}>
            <Form.Item
              name="email"
              rules={[{ required: true, message: 'Please enter your email!' }]}
            >
              <Input
                style={{ padding: '10px 15px' }}
                prefix={<UserOutlined style={{ color: '#888' }} />}
                placeholder="Email"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[{ required: true, message: 'Please enter your password!' }]}
            >
              <Input.Password
                style={{ padding: '10px 15px' }}
                prefix={<LockOutlined style={{ color: '#888' }} />}
                placeholder="Password"
              />
            </Form.Item>
            <Form.Item shouldUpdate>
              {() => {
                return (
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{
                      width: '50%',
                      fontSize: 20,
                      fontWeight: 'bold',
                      borderRadius: 20,
                      height: 50,
                      backgroundColor: '#004990',
                      borderColor: '#004990',
                    }}
                  >
                    Login
                  </Button>
                );
              }}
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Login;
