import React, { useEffect } from 'react';
import { Spin, Alert } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { fetchStudentSessions } from './sessions.api';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

const StudentSessions = () => {
  const dispatch = useDispatch();
  const { loading, sessions, error } = useSelector((state) => state.sessions);

  useEffect(() => {
    dispatch(fetchStudentSessions());
  }, [dispatch]);

  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  if (loading) {
    return <Spin tip="Loading sessions..." />;
  }

  if (error) {
    return (
      <Alert message="Error" description={error.message} type="error" showIcon />
    );
  }

  const events = sessions.map(session => ({
    id: session.id,
    title: `${session.name} (Capacity: ${session.capacity})`,
    start: session.startDatetime,
    end: session.endDatetime,
    backgroundColor: getRandomColor(),
    borderColor: getRandomColor(),
    extendedProps: {
      capacity: session.capacity,
      campus_id: session.campusId,
      students: session?.attendance?.map(s => s.student?.id),
      student_pcs: session?.attendance?.map((item) => ({
        pc_id: item.pc?.id,
        pc_name: item.pc?.name,
        student_id: item.student?.id,
      })),
    },
  }));

  const eventDidMount = (info) => {
    const { event } = info;
    console.log("event", event.extendedProps.student_pcs)
    const studentsPCs = event.extendedProps.student_pcs;
    let tooltipContent = `
      ${event.title} <br>
      From: ${new Date(event.start).toLocaleString()} <br>
      To: ${new Date(event.end).toLocaleString()} <br>
      Capacity: ${event.extendedProps.capacity}
    `;

    if (studentsPCs?.length > 0) {
      tooltipContent += `<br>PC: ${studentsPCs.map(pc => pc.pc_name).join(', ')}`;
    }

    tippy(info.el, {
      content: tooltipContent,
      allowHTML: true,
      placement: 'top',
      animation: 'scale',
    });
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h2>Sessions</h2>
      </div>

      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView="timeGridDay"
        events={events}
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'timeGridDay,timeGridWeek,dayGridMonth',
        }}
        eventDidMount={eventDidMount}
      />
    </div>
  );
};

export default StudentSessions;
