import React, { useEffect, useState } from 'react';
import {
  Card,
  Row,
  Col,
  Select,
  Spin,
  Alert,
  Button,
  Modal,
  Form,
  Input,
  Tooltip,
  Empty,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCampuses, createCampus, updateCampus, deleteCampus } from './campuses.api'; // Adjust the import according to your file structure

const { Meta } = Card;
const { Option } = Select;

const truncateText = (text, length) => {
  return text?.length > length ? `${text.substring(0, length)}...` : text;
};

const CampusCard = () => {
  const [campusesToShow, setCampusesToShow] = useState(6); // Default number of campuses to show
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [currentCampus, setCurrentCampus] = useState(null);
  const dispatch = useDispatch();
  const { campuses, loading, error } = useSelector((state) => state.campuses);

  // Function to generate a random gradient
  const generateRandomGradient = () => {
    const randomAngle = Math.floor(Math.random() * 360);
    const color1 = '#' + Math.floor(Math.random() * 16777215).toString(16); // Random hex color
    const color2 = '#' + Math.floor(Math.random() * 16777215).toString(16); // Random hex color
    return `linear-gradient(${randomAngle}deg, ${color1}, ${color2})`;
  };

  useEffect(() => {
    const adminId = localStorage.getItem('adminId');
    if (adminId) {
      dispatch(fetchCampuses(adminId));
    }
  }, [dispatch]);

  const handleSelectChange = (value) => {
    setCampusesToShow(value);
  };

  const handleAddCampus = (values) => {
    if (currentCampus) {
      dispatch(updateCampus({ ...values, id: currentCampus.id })).then(() => {
        setIsModalVisible(false);
        form.resetFields();
        setCurrentCampus(null);
        dispatch(fetchCampuses());
      });
    } else {
      dispatch(createCampus(values)).then(() => {
        setIsModalVisible(false);
        form.resetFields();
        dispatch(fetchCampuses());
      });
    }
  };

  const handleDeleteCampus = (campusId) => {
    dispatch(deleteCampus(campusId)).then(() => {
      dispatch(fetchCampuses());
    });
  };

  const handleEditClick = (campus) => {
    setCurrentCampus(campus);
    form.setFieldsValue({
      name: campus.name,
      address: campus.address,
      pc_capacity: campus.pcCapacity,
    });
    setIsModalVisible(true);
  };

  if (loading) {
    return <Spin tip="Loading campuses..." />;
  }

  if (error) {
    return <Alert message="Error" description={error} type="error" showIcon />;
  }

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
        <h2 style={{ margin: 0 }}>Campuses</h2>
        <p style={{ marginLeft: 'auto' }}>
          Show &nbsp;
          <Select defaultValue={campusesToShow.toString()} onChange={handleSelectChange}>
            <Option value="3">3</Option>
            <Option value="6">6</Option>
            <Option value="9">9</Option>
            <Option value="14">14</Option>
            <Option value="20">20</Option>
            <Option value="30">30</Option>
          </Select>
          &nbsp;
          <Button type="primary" onClick={() => setIsModalVisible(true)}>
            Add Campus
          </Button>
        </p>
      </div>
      {campuses.length === 0 ? (
        <Empty description="No campuses available" />
      ) : (
        <Row gutter={[16, 16]}>
          {campuses.slice(0, campusesToShow).map((campus) => (
            <Col key={campus.id} span={8}>
              <Card
                hoverable
                style={{ width: '100%', borderRadius: 20, marginBottom: '20px' }}
                cover={
                  <div
                    style={{
                      background: generateRandomGradient(),
                      height: '160px',
                      borderTopRightRadius: 20,
                      borderTopLeftRadius: 20,
                      backgroundSize: 'cover',
                    }}
                  ></div>
                }
                actions={[
                  <Button key="edit" type="link" onClick={() => handleEditClick(campus)}>
                    Edit
                  </Button>,
                  <Button
                    key="delete"
                    type="link"
                    danger
                    onClick={() => handleDeleteCampus(campus.id)}
                  >
                    Delete
                  </Button>,
                ]}
              >
                <div style={{ minHeight: '100px' }}>
                  <Meta
                    title={
                      <Tooltip title={campus.name}>
                        {truncateText(campus?.name, 20)}
                      </Tooltip>
                    }
                    description={
                      <Tooltip title={campus.pcCapacity}>
                        <b>PC Capacity:</b> {truncateText(campus?.pcCapacity, 40)}
                      </Tooltip>
                    }
                  />
                  <Meta
                    description={
                      <Tooltip title={campus.address}>
                        <b>Address:</b> {truncateText(campus?.address, 40)}
                      </Tooltip>
                    }
                  />
                </div>
              </Card>
            </Col>
          ))}
        </Row>
      )}
      <Modal
        title={currentCampus ? "Edit Campus" : "Add New Campus"}
        visible={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false);
          setCurrentCampus(null);
          form.resetFields();
        }}
        footer={null}
      >
        <Form form={form} onFinish={handleAddCampus}>
          <Form.Item
            name="name"
            label="Campus Name"
            rules={[
              { required: true, message: 'Please input the campus name!' },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="address"
            label="Address"
            rules={[
              { required: true, message: 'Please input the campus address!' },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="pc_capacity"
            label="PC Capacity"
            rules={[
              { required: true, message: 'Please input the pc capacity' },
            ]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {currentCampus ? "Update Campus" : "Add Campus"}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default CampusCard;
