import React, { useEffect } from 'react';
import { Form, Input, Button, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCampuses } from '../../Campuses/campuses.api'; // Adjust the import according to your file structure

const { Option } = Select;

// eslint-disable-next-line react/prop-types
const AddCourseForm = ({ onSubmit, form }) => {
  const dispatch = useDispatch();
  const { campuses } = useSelector((state) => state.campuses);

  useEffect(() => {
    dispatch(fetchCampuses());
  }, [dispatch]);

  return (
    <Form form={form} layout="vertical" onFinish={onSubmit}>
      <Form.Item
        name="title"
        label="Course Title"
        rules={[{ required: true, message: 'Please enter course name' }]}
      >
        <Input placeholder="Course Title" />
      </Form.Item>
      <Form.Item
        name="campus_id"
        label="Campus"
        rules={[{ required: true, message: 'Please select a campus' }]}
      >
        <Select placeholder="Select Campus">
          {campuses.map((campus) => (
            <Option key={campus.id} value={campus.id}>
              {campus.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AddCourseForm;
