import request from '../../core/request'
import { openNotification } from '../../utils/Notification'
import {
  fetchStatsRequest,
  fetchStatsSuccess,
  fetchStatsFailure,
} from './dashboard.actions'

// Function to handle fetching Stats
export const fetchDashboardStats = (campusId) => {
  return (dispatch) => {
    dispatch(fetchStatsRequest())
    return request({
      method: 'GET',
      url: `/dashboard/stats?stats_date=${new Date().toISOString().split('T')[0]}&campus_id=${campusId}`,
    })
      .then((response) => {
        console.log("response", response)
        if (response?.data) {
          dispatch(fetchStatsSuccess(response.data))
        } else {
          dispatch(fetchStatsFailure('Fetching Stats failed'))
          openNotification({
            type: 'error',
            title: 'Fetching Stats Failed',
            description: 'Fetching Stats failed',
          })
        }
      })
      .catch((error) => {
        if(error?.response?.status === 404){
          dispatch(fetchStatsSuccess([]))
        }
        else {
          dispatch(
            fetchStatsFailure('An error occurred while fetching Stats'),
          )
          openNotification({
            type: 'error',
            title: 'Fetching Stats Error',
            description: `An error occurred while fetching Stats: ${error.message}`,
          })
        }
      })
  }
}

