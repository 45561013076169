// Action Creators

// Action Types
export const FETCH_SESSIONS_REQUEST = 'FETCH_SESSIONS_REQUEST'
export const FETCH_SESSIONS_SUCCESS = 'FETCH_SESSIONS_SUCCESS'
export const FETCH_SESSIONS_FAILURE = 'FETCH_SESSIONS_FAILURE'

export const CREATE_SESSION_REQUEST = 'CREATE_SESSION_REQUEST'
export const CREATE_SESSION_SUCCESS = 'CREATE_SESSION_SUCCESS'
export const CREATE_SESSION_FAILURE = 'CREATE_SESSION_FAILURE'

export const UPDATE_SESSION_REQUEST = 'UPDATE_SESSION_REQUEST'
export const UPDATE_SESSION_SUCCESS = 'UPDATE_SESSION_SUCCESS'
export const UPDATE_SESSION_FAILURE = 'UPDATE_SESSION_FAILURE'

export const DELETE_SESSION_REQUEST = 'DELETE_SESSION_REQUEST'
export const DELETE_SESSION_SUCCESS = 'DELETE_SESSION_SUCCESS'
export const DELETE_SESSION_FAILURE = 'DELETE_SESSION_FAILURE'

export const SEARCH_SESSIONS_REQUEST = 'SEARCH_SESSIONS_REQUEST'
export const SEARCH_SESSIONS_SUCCESS = 'SEARCH_SESSIONS_SUCCESS'
export const SEARCH_SESSIONS_FAILURE = 'SEARCH_SESSIONS_FAILURE'

export const FETCH_AVAILABLE_PCS_REQUEST = 'FETCH_AVAILABLE_PCS_REQUEST';
export const FETCH_AVAILABLE_PCS_SUCCESS = 'FETCH_AVAILABLE_PCS_SUCCESS';
export const FETCH_AVAILABLE_PCS_FAILURE = 'FETCH_AVAILABLE_PCS_FAILURE';

export const fetchAvailablePCsRequest = () => ({
  type: FETCH_AVAILABLE_PCS_REQUEST,
});

export const fetchAvailablePCsSuccess = (data) => ({
  type: FETCH_AVAILABLE_PCS_SUCCESS,
  payload: data,
});

export const fetchAvailablePCsFailure = (error) => ({
  type: FETCH_AVAILABLE_PCS_FAILURE,
  payload: error,
});

export const fetchSessionsRequest = () => ({
  type: FETCH_SESSIONS_REQUEST,
})

export const fetchSessionsSuccess = (sessions) => ({
  type: FETCH_SESSIONS_SUCCESS,
  payload: sessions,
})

export const fetchSessionsFailure = (error) => ({
  type: FETCH_SESSIONS_FAILURE,
  payload: error,
})

export const createSessionRequest = (session) => ({
  type: CREATE_SESSION_REQUEST,
  payload: session,
})

export const createSessionSuccess = (session) => ({
  type: CREATE_SESSION_SUCCESS,
  payload: session,
})

export const createSessionFailure = (error) => ({
  type: CREATE_SESSION_FAILURE,
  payload: error,
})

export const updateSessionRequest = (session) => ({
  type: UPDATE_SESSION_REQUEST,
  payload: session,
})

export const updateSessionSuccess = (session) => ({
  type: UPDATE_SESSION_SUCCESS,
  payload: session,
})

export const updateSessionFailure = (error) => ({
  type: UPDATE_SESSION_FAILURE,
  payload: error,
})

export const deleteSessionRequest = (sessionId) => ({
  type: DELETE_SESSION_REQUEST,
  payload: sessionId,
})

export const deleteSessionSuccess = (sessionId) => ({
  type: DELETE_SESSION_SUCCESS,
  payload: sessionId,
})

export const deleteSessionFailure = (error) => ({
  type: DELETE_SESSION_FAILURE,
  payload: error,
})

export const searchSessionsRequest = () => ({
  type: SEARCH_SESSIONS_REQUEST,
})

export const searchSessionsSuccess = (searchedSessions) => ({
  type: SEARCH_SESSIONS_SUCCESS,
  payload: searchedSessions,
})

export const searchSessionsFailure = (error) => ({
  type: SEARCH_SESSIONS_FAILURE,
  payload: error,
})
