import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, DatePicker, Select, Tag, Button, Tooltip, Spin } from 'antd';
import { CalendarOutlined, InfoCircleOutlined, DownloadOutlined } from '@ant-design/icons';
import { fetchReports, fetchStudentDetails } from './reports.api';
import './Reports.css';
import { fetchCampuses } from '../Campuses/campuses.api';
import { fetchSessions, searchSessions } from '../Sessions/sessions.api';
import { fetchStatuses } from './reports.api';
import { fetchStudents, searchStudents } from '../Students/students.api';
import StudentDetails from './StudentDetails';
import { fetchCourses } from '../Courses/courses.api';

const { Option } = Select;

const Reports = () => {
  const { loading, reports, error, studentDetails, studentDetailsLoading, studentDetailsError } = useSelector((state) => state.reports);
  const { campuses } = useSelector((state) => state.campuses);
  const { courses } = useSelector((state) => state.courses);
  const { sessions, searchedSessions, loading: loadingSessions } = useSelector((state) => state.sessions); // Add searchedSessions and loadingSessions
  const { statuses } = useSelector((state) => state.reports);
  const { students, searchedStudents, loading: loadingStudents } = useSelector((state) => state.students);
  const dispatch = useDispatch();

  const [filters, setFilters] = useState({
    student_id: '',
    campus_id: 'all',
    session_id: 'all',
    status_id: 'all',
    from_date: null,
    to_date: null,
  });

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [studentQuery, setStudentQuery] = useState('');
  const [sessionQuery, setSessionQuery] = useState(''); // Add state for session query

  useEffect(() => {
    dispatch(fetchCampuses());
    dispatch(fetchSessions());
    dispatch(fetchStatuses());
    dispatch(fetchStudents());
    dispatch(fetchCourses());
  }, [dispatch]);

  useEffect(() => {
    const filteredFilters = Object.fromEntries(
      // eslint-disable-next-line no-unused-vars
      Object.entries(filters).filter(([key, value]) => value && value !== 'all')
    );
    dispatch(fetchReports(filteredFilters));
  }, [dispatch, filters]);

  const handleFilterChange = (filterName, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: value,
    }));
  };

  const handleSearchStudents = (query) => {
    setStudentQuery(query);
    if (query) {
      dispatch(searchStudents(query));
    } else {
      dispatch(fetchStudents());
    }
  };

  const convertToLocalTime = (utcDate) => {
    // Append 'Z' to indicate UTC if not already present
    if (!utcDate.endsWith('Z')) {
      utcDate += 'Z';
    }
    return new Date(utcDate).toLocaleString(); // Automatically converts to the user's local time zone
  };

  const handleSearchSessions = (query) => {
    setSessionQuery(query); // Set the session query state
    if (query) {
      dispatch(searchSessions(query));
    } else {
      dispatch(fetchSessions());
    }
  };

  const handleDownloadCSV = () => {
    const filteredFilters = Object.fromEntries(
      // eslint-disable-next-line no-unused-vars
      Object.entries(filters).filter(([key, value]) => value && value !== 'all')
    );
    dispatch(fetchReports(filteredFilters, true));
  };

  const handleInfoClick = (studentId) => {
    dispatch(fetchStudentDetails(studentId))
      .then(() => {
        setIsModalVisible(true);
      });
  };

  const columns = [
    {
      title: 'Student Name',
      dataIndex: ['student', 'name'],
      key: 'studentName',
      render: (text, record) => (
        <>
          <b>{text}</b>
          <InfoCircleOutlined
            style={{ marginLeft: 8 }}
            onClick={() => handleInfoClick(record.student.id)}
          />
        </>
      ),
    },
    { title: 'Student Email', dataIndex: ['student', 'email'], key: 'studentEmail' },
    { title: 'Campus Name', dataIndex: ['campus', 'name'], key: 'campusName' },
    { title: 'Session Name', dataIndex: ['session', 'name'], key: 'sessionName' },
    { title: 'Course Name', dataIndex: ['session', 'courseName'], key: 'courseName' },
    {
      title: 'Check-In',
      dataIndex: 'checkinDatetime',
      key: 'checkIn',
      render: (text) => (text ? convertToLocalTime(text) : '-'),
    },
    {
      title: 'Check-Out',
      dataIndex: 'checkoutDatetime',
      key: 'checkOut',
      render: (text) => (text ? convertToLocalTime(text) : '-'),
    },
    {
      title: 'Status',
      dataIndex: 'statuses',
      key: 'statuses',
      render: (statuses) => {
        return statuses.map((statusObj) => {
          let color = 'blue';
          switch (statusObj.status) {
            case 'LEFT_EARLY':
              color = 'volcano';
              break;
            case 'ARRIVE_LATE':
              color = 'orange';
              break;
            case 'PRESENT':
              color = 'green';
              break;
            case 'ABSENT':
              color = 'red';
              break;
            case 'INFORMED_ABSENT':
              color = 'gold';
              break;
            case 'CANCELED_SESSION':
              color = 'gray';
              break;
            case 'PENDING':
              color = 'purple';
              break;
            default:
              color = 'blue';
          }
          return <Tag color={color} key={statusObj.id}>{statusObj.status}</Tag>;
        });
      },
    },
  ];

  const dataSource = Array.isArray(reports)
    ? reports.map((report) => ({
      ...report,
      studentName: `${report.student.first_name} ${report.student.last_name}`,
      campusName: report.campus.name,
      sessionName: report.session.name,
      checkin_datetime: report.checkin_datetime ? new Date(report.checkin_datetime).toLocaleString() : '-',
      checkout_datetime: report.checkout_datetime ? new Date(report.checkout_datetime).toLocaleString() : '-',
      status: report.statuses.length ? report.statuses[0].status : '-',
      attendancePercentage: report.attendancePercentage || '-',
    }))
    : [];

  const studentOptions = studentQuery ? searchedStudents : students;
  const sessionOptions = sessionQuery ? searchedSessions : sessions; // Use sessionOptions based on query

  return (
    <div className="reports-container">
      <div className="reports-header">
        <h2>Reports</h2>
      </div>

      <div className="reports-filters">
        <h3 className="all-reports-text">All Reports</h3>
        <div className="filter-options">
          <div>
            <div className="filter-label">Campus</div>
            <Select
              defaultValue="all"
              className="filter-select campus-select"
              onChange={(value) => handleFilterChange('campus_id', value)}
            >
              <Option value="all">All</Option>
              {campuses.map((campus) => (
                <Option key={campus.id} value={campus.id}>
                  {campus.name}
                </Option>
              ))}
            </Select>
          </div>
          <div>
            <div className="filter-label">From</div>
            <DatePicker
              placeholder="00/00/0000"
              suffixIcon={<CalendarOutlined />}
              className="filter-datepicker custom-from-datepicker"
              onChange={(date, dateString) => handleFilterChange('from_date', dateString)}
            />
          </div>
          <div>
            <div className="filter-label">Till</div>
            <DatePicker
              placeholder="00/00/0000"
              suffixIcon={<CalendarOutlined />}
              className="filter-datepicker custom-datepicker"
              onChange={(date, dateString) => handleFilterChange('to_date', dateString)}
            />
          </div>
          <div>
            <div className="filter-label">Session</div>
            <Select
              showSearch
              placeholder="Search Session"
              optionFilterProp="children"
              onSearch={handleSearchSessions} // Add onSearch handler for sessions
              onChange={(value) => handleFilterChange('session_id', value)}
              filterOption={false}
              notFoundContent={loadingSessions ? <Spin size="small" /> : null}
              className="search-input"
            >
              <Option value="all">All</Option>
              {sessionOptions.map((session) => (
                <Option key={session.id} value={session.id}>
                  {session.name}
                </Option>
              ))}
            </Select>
          </div>
          <div>
            <div className="filter-label">Status</div>
            <Select
              defaultValue="all"
              className="filter-select status-select"
              onChange={(value) => handleFilterChange('status_id', value)}
            >
              <Option value="all">All</Option>
              {statuses.map((status) => (
                <Option key={status.id} value={status.id}>
                  {status.status}
                </Option>
              ))}
            </Select>
          </div>
          <div>
            <div className="filter-label">Course</div>
            <Select
              defaultValue="all"
              className="filter-select course-select"
              onChange={(value) => handleFilterChange('course_id', value)}
            >
              <Option value="all">All</Option>
              {courses.map((course) => (
                <Option key={course.id} value={course.id}>
                  {course.title}
                </Option>
              ))}
            </Select>
          </div>
          <div>
            <div className="filter-label">Student</div>
            <Select
              showSearch
              placeholder="Search Student"
              optionFilterProp="children"
              onSearch={handleSearchStudents}
              onChange={(value) => handleFilterChange('student_id', value)}
              filterOption={false}
              notFoundContent={loadingStudents ? <Spin size="small" /> : null}
              className="search-input"
            >
              {studentOptions.map((student) => (
                <Option key={student.id} value={student.id}>
                  {`${student.firstName} ${student.lastName}`}
                </Option>
              ))}
            </Select>
          </div>
          <div className="download-csv-button1">
            <Tooltip title="Download CSV">
              <Button
                type="primary"
                icon={<DownloadOutlined />}
                onClick={handleDownloadCSV}
                style={{ backgroundColor: 'green', borderColor: 'green', marginTop: '150%', marginLeft: 10 }}
              />
            </Tooltip>
          </div>
        </div>
      </div>

      <Table
        columns={columns}
        dataSource={dataSource}
        rowKey="id"
        pagination={false}
        loading={loading}
        className="reports-table"
      />
      {error && <div className="error-message">{error}</div>}

      <StudentDetails
        isModal={true}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        loading={studentDetailsLoading}
        error={studentDetailsError}
        studentDetails={studentDetails}
      />
    </div>
  );
};

export default Reports;
