import isEmpty from 'lodash/isEmpty'
import each from 'lodash/each'
import isArray from 'lodash/isArray'
import isObject from 'lodash/isObject'
import isString from 'lodash/isString'
import camelCase from 'lodash/camelCase'
import snakeCase from 'lodash/snakeCase'
import startCase from 'lodash/startCase'
import forIn from 'lodash/forIn'

const transform = (body, transformFn, type) => {
  const transformObj = (obj) => {
    // eslint-disable-next-line no-prototype-builtins
    ;(obj?.hasOwnProperty('length') ? forIn : each)(obj, (value, key) => {
      delete obj[key]
      if (type === 'snakeCase') {
        switch (key) {
          case 'ipv4CidrBlock':
            obj.ipv4_cidr_block = value
            break

          case 'publicIpv4Pool':
            obj.public_ipv4_pool = value
            break

          case 'primaryIpv4Address':
            obj.primary_ipv4_address = value
            break

          case 'ipv4Address':
            obj.ipv4_address = value
            break

          case 'destinationIpv4CidrBlock':
            obj.destination_ipv4_cidr_block = value
            break

          case 'phase1DhGroupNumbers':
            obj.phase1_dh_group_numbers = value
            break

          case 'phase2DhGroupNumbers':
            obj.phase2_dh_group_numbers = value
            break

          case 'phase1IntegrityAlgorithms':
            obj.phase1_integrity_algorithms = value
            break

          case 'phase2IntegrityAlgorithms':
            obj.phase2_integrity_algorithms = value
            break

          case 'phase1EncryptionAlgorithms':
            obj.phase1_encryption_algorithms = value
            break

          case 'phase2EncryptionAlgorithms':
            obj.phase2_encryption_algorithms = value
            break

          case 'ikeVersions':
            obj.ike_versions = value
            break

          case 'phase1LifetimeSeconds':
            obj.phase1_lifetime_seconds = value
            break

          case 'phase2LifetimeSeconds':
            obj.phase2_lifetime_seconds = value
            break

          case 'ipv6Support':
            obj.ipv6_support = value
            break

          case 'isIpv6Enabled':
            obj.is_ipv6_enabled = value
            break

          case 'k8sClusterName':
            obj.k8s_cluster_name = value
            break

          case 'k8sClusterId':
            obj.k8s_cluster_id = value
            break

          case 'k8sSchedulePolicy':
            obj.k8s_schedule_policy = value
            if (!isString(value)) {
              transformObj(value)
            }
            break

          case 'k8SClusters':
            obj.k8s_clusters = value
            if (!isString(value)) {
              transformObj(value)
            }
            break

          case 'k8sClusters':
            obj.k8s_clusters = value
            if (!isString(value)) {
              transformObj(value)
            }
            break

          case 'localIpv4NetworkCidr':
            obj.local_ipv4_network_cidr = value
            break

          case 'localIpv6NetworkCidr':
            obj.local_ipv6_network_cidr = value
            break

          case 'remoteIpv6NetworkCidr':
            obj.remote_ipv6_network_cidr = value
            break

          case 'remoteIpv4NetworkCidr':
            obj.remote_ipv4_network_cidr = value
            break

          case 'preservedState':
            obj.preserved_state = value
            break

          case 's3Buckets':
            obj.s3_buckets = value
            break

          default:
            if (isString(key) && key.includes('/')) {
              obj[key] = value
            } else {
              obj[transformFn(key)] = value
            }

            if (!isString(value)) {
              transformObj(value)
            }
            break
        }
      } else {
        // TODO: Need to revisit if we can remove redundant code @Kuldeep Kumar
        if (isString(key) && key.includes('/')) {
          obj[key] = value
        } else {
          obj[transformFn(key)] = value
        }

        if (!isString(value)) {
          transformObj(value)
        }
      }
    })
  }

  if (isArray(body)) {
    each(body, (obj) => {
      transformObj(obj)
    })
  } else if (isObject(body)) {
    transformObj(body)
  }

  return body
}

const transformKeys = {
  toCamelCase: (body) => {
    return isEmpty(body) ? body : transform(body, camelCase, 'camelCase')
  },
  toSnakeCase: (body) => {
    return isEmpty(body) ? body : transform(body, snakeCase, 'snakeCase')
  },
  toTitleCase: (body) => {
    return isEmpty(body) ? body : transform(body, startCase, 'startCase')
  },
}

export default transformKeys
