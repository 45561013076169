// reducers.js
import {
  FETCH_STATS_REQUEST,
  FETCH_STATS_SUCCESS,
  FETCH_STATS_FAILURE
} from './dashboard.actions'

const initialState = {
  counter: 0,
}

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_STATS_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case FETCH_STATS_SUCCESS:
      return {
        ...state,
        loading: false,
        stats: action.payload,
        error: null,
      }
    case FETCH_STATS_FAILURE:
      return {
        ...state,
        loading: false,
        stats: [],
        error: action.payload,
      }
    default:
      return state
  }
}

export default dashboardReducer
