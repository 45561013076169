// Action Creators

// Action Types
export const FETCH_REPORTS_REQUEST = 'FETCH_REPORTS_REQUEST'
export const FETCH_REPORTS_SUCCESS = 'FETCH_REPORTS_SUCCESS'
export const FETCH_REPORTS_FAILURE = 'FETCH_REPORTS_FAILURE'

export const CREATE_REPORT_REQUEST = 'CREATE_REPORT_REQUEST'
export const CREATE_REPORT_SUCCESS = 'CREATE_REPORT_SUCCESS'
export const CREATE_REPORT_FAILURE = 'CREATE_REPORT_FAILURE'

export const UPDATE_REPORT_REQUEST = 'UPDATE_REPORT_REQUEST'
export const UPDATE_REPORT_SUCCESS = 'UPDATE_REPORT_SUCCESS'
export const UPDATE_REPORT_FAILURE = 'UPDATE_REPORT_FAILURE'

export const DELETE_REPORT_REQUEST = 'DELETE_REPORT_REQUEST'
export const DELETE_REPORT_SUCCESS = 'DELETE_REPORT_SUCCESS'
export const DELETE_REPORT_FAILURE = 'DELETE_REPORT_FAILURE'

export const SEARCH_REPORTS_SUCCESS = 'SEARCH_REPORTS_SUCCESS'
export const SEARCH_REPORTS_FAILURE = 'SEARCH_REPORTS_FAILURE'

export const FETCH_STATUSES_REQUEST = 'FETCH_STATUSES_REQUEST'
export const FETCH_STATUSES_SUCCESS = 'FETCH_STATUSES_SUCCESS'
export const FETCH_STATUSES_FAILURE = 'FETCH_STATUSES_FAILURE'

export const FETCH_STUDENT_DETAILS_REQUEST = 'FETCH_STUDENT_DETAILS_REQUEST'
export const FETCH_STUDENT_DETAILS_SUCCESS = 'FETCH_STUDENT_DETAILS_SUCCESS'
export const FETCH_STUDENT_DETAILS_FAILURE = 'FETCH_STUDENT_DETAILS_FAILURE'

export const fetchReportsRequest = () => ({
  type: FETCH_REPORTS_REQUEST,
})

export const fetchReportsSuccess = (reports, csv) => ({
  type: FETCH_REPORTS_SUCCESS,
  payload: { reports, csv }
})

export const fetchReportsFailure = (error) => ({
  type: FETCH_REPORTS_FAILURE,
  payload: error,
})

export const fetchStudentDetailsRequest = () => ({
  type: FETCH_STUDENT_DETAILS_REQUEST,
})

export const fetchStudentDetailsSuccess = (studentDetails) => ({
  type: FETCH_STUDENT_DETAILS_SUCCESS,
  payload: studentDetails
})

export const fetchStudentDetailsFailure = (error) => ({
  type: FETCH_STUDENT_DETAILS_FAILURE,
  payload: error,
})

export const fetchStatusesRequest = () => ({
  type: FETCH_STATUSES_REQUEST,
})

export const fetchStatusesSuccess = (statuses) => ({
  type: FETCH_STATUSES_SUCCESS,
  payload: statuses,
})

export const fetchStatusesFailure = (error) => ({
  type: FETCH_STATUSES_FAILURE,
  payload: error,
})

export const createReportRequest = (report) => ({
  type: CREATE_REPORT_REQUEST,
  payload: report,
})

export const createReportSuccess = (report) => ({
  type: CREATE_REPORT_SUCCESS,
  payload: report,
})

export const createReportFailure = (error) => ({
  type: CREATE_REPORT_FAILURE,
  payload: error,
})

export const updateReportRequest = (report) => ({
  type: UPDATE_REPORT_REQUEST,
  payload: report,
})

export const updateReportSuccess = (report) => ({
  type: UPDATE_REPORT_SUCCESS,
  payload: report,
})

export const updateReportFailure = (error) => ({
  type: UPDATE_REPORT_FAILURE,
  payload: error,
})

export const deleteReportRequest = (reportId) => ({
  type: DELETE_REPORT_REQUEST,
  payload: reportId,
})

export const deleteReportSuccess = (reportId) => ({
  type: DELETE_REPORT_SUCCESS,
  payload: reportId,
})

export const deleteReportFailure = (error) => ({
  type: DELETE_REPORT_FAILURE,
  payload: error,
})

export const searchReportsSuccess = (searchedReports) => ({
  type: SEARCH_REPORTS_SUCCESS,
  payload: searchedReports,
})

export const searchReportsFailure = (error) => ({
  type: SEARCH_REPORTS_FAILURE,
  payload: error,
})
