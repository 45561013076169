import React, { useEffect } from 'react'
import { Layout } from 'antd'
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  useNavigate,
} from 'react-router-dom'
import SidebarMenu from './sidebar/SidebarMenu'
import Dashboard from './components/Dashboard/dashboard.container'
import {
  LoginContainer,
} from './components/Auth/auth.container'
import { StudentsContainer } from './components/Students/students.container'
import { AdminsContainer } from './components/Admins/admins.container'
import { AttendanceContainer } from './components/Attendance/attendance.container'
import { SessionsContainer } from './components/Sessions/sessions.container'
import './App.css'
import ProtectedRoute from './ProtectedRoute'
import { CoursesContainer } from './components/Courses/courses.container'
import StudentSessions from './components/Sessions/StudentSessions'
import useAxiosInterceptor from './core/useAxiosIntercepter'
import { ReportsContainer } from './components/Reports/reports.container'
import { CampusesContainer } from './components/Campuses/campuses.container'
import StudentReports from './components/Reports/StudentReports'

const { Content } = Layout

const AppContent = () => {
  useAxiosInterceptor()
  const isAuthenticated = Boolean(localStorage.getItem('isAuthenticated'))
  const navigate = useNavigate()

  useEffect(() => {
    if (
      !isAuthenticated &&
      location.pathname !== '/login' &&
      location.pathname !== '/register'
    ) {
      // Redirect to login if the user is not authenticated and trying to access other routes
      navigate('/login')
    }
  }, [isAuthenticated, location.pathname])
  return (
    <Layout style={{ minHeight: '100vh', overflow: 'hidden' }}>
      {isAuthenticated && <SidebarMenu collapsed={false} />}
      <Layout style={{ marginLeft: isAuthenticated ? 256 : 0 }}>
        <Layout
          className="site-layout"
          style={{ padding: '24px 24px 24px', backgroundColor: '#E8E8E8' }}
        >
          <Content style={{ padding: 24, margin: 0, minHeight: 280 }}>
            <Routes>
              {!isAuthenticated && (
                <>
                  <Route path="*" element={<Navigate to="/login" replace />} />
                  <Route path="/login" element={<LoginContainer />} />
                </>
              )}
              {isAuthenticated && (
                <>
                  <Route
                    path="/dashboard"
                    element={
                      <ProtectedRoute allowedRoles={['admin']}>
                        <Dashboard />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/students"
                    element={
                      <ProtectedRoute allowedRoles={['admin']}>
                        <StudentsContainer />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/admins"
                    element={
                      <ProtectedRoute allowedRoles={['admin']}>
                        <AdminsContainer />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/campuses"
                    element={
                      <ProtectedRoute allowedRoles={['admin']}>
                        <CampusesContainer />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/courses"
                    element={
                      <ProtectedRoute allowedRoles={['admin']}>
                        <CoursesContainer />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/sessions"
                    element={
                      <ProtectedRoute allowedRoles={['admin']}>
                        <SessionsContainer />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/sessions/:session_id/attendance"
                    element={
                      <ProtectedRoute allowedRoles={['admin']}>
                        <AttendanceContainer />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/reports"
                    element={
                      <ProtectedRoute allowedRoles={['admin']}>
                        <ReportsContainer />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/sessions/:studentId"
                    element={
                      <ProtectedRoute allowedRoles={['student']}>
                        <StudentSessions />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/reports/:studentId"
                    element={
                      <ProtectedRoute allowedRoles={['student']}>
                        <StudentReports />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/unauthorized"
                    element={<div>Unauthorized Access</div>}
                  />
                </>
              )}
            </Routes>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  )
}

const App = () => (
  <BrowserRouter>
    <AppContent />
  </BrowserRouter>
)

export default App
