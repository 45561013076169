import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStudentDetails } from './reports.api';
import StudentDetails from './StudentDetails';

const StudentReports = () => {
  const dispatch = useDispatch();
  const { studentDetails, loading, error } = useSelector((state) => state.reports);

  useEffect(() => {
    const studentId = localStorage.getItem('studentId');
    dispatch(fetchStudentDetails(studentId));
  }, [dispatch]);

  return (
    <div className="student-profile-container">
      <StudentDetails
        isModal={false}
        visible={true}
        loading={loading}
        error={error}
        studentDetails={studentDetails}
      />
    </div>
  );
};

export default StudentReports;
