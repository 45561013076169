// Action Creators

// Action Types
export const FETCH_ATTENDANCES_REQUEST = 'FETCH_ATTENDANCES_REQUEST'
export const FETCH_ATTENDANCES_SUCCESS = 'FETCH_ATTENDANCES_SUCCESS'
export const FETCH_ATTENDANCES_FAILURE = 'FETCH_ATTENDANCES_FAILURE'

export const CREATE_ATTENDANCE_REQUEST = 'CREATE_ATTENDANCE_REQUEST'
export const CREATE_ATTENDANCE_SUCCESS = 'CREATE_ATTENDANCE_SUCCESS'
export const CREATE_ATTENDANCE_FAILURE = 'CREATE_ATTENDANCE_FAILURE'

export const UPDATE_ATTENDANCE_REQUEST = 'UPDATE_ATTENDANCE_REQUEST'
export const UPDATE_ATTENDANCE_SUCCESS = 'UPDATE_ATTENDANCE_SUCCESS'
export const UPDATE_ATTENDANCE_FAILURE = 'UPDATE_ATTENDANCE_FAILURE'

export const DELETE_ATTENDANCE_REQUEST = 'DELETE_ATTENDANCE_REQUEST'
export const DELETE_ATTENDANCE_SUCCESS = 'DELETE_ATTENDANCE_SUCCESS'
export const DELETE_ATTENDANCE_FAILURE = 'DELETE_ATTENDANCE_FAILURE'

export const SEARCH_ATTENDANCES_SUCCESS = 'SEARCH_ATTENDANCES_SUCCESS'
export const SEARCH_ATTENDANCES_FAILURE = 'SEARCH_ATTENDANCES_FAILURE'

export const fetchAttendancesRequest = () => ({
  type: FETCH_ATTENDANCES_REQUEST,
})

export const fetchAttendancesSuccess = (attendances) => ({
  type: FETCH_ATTENDANCES_SUCCESS,
  payload: attendances,
})

export const fetchAttendancesFailure = (error) => ({
  type: FETCH_ATTENDANCES_FAILURE,
  payload: error,
})

export const createAttendanceRequest = (attendance) => ({
  type: CREATE_ATTENDANCE_REQUEST,
  payload: attendance,
})

export const createAttendanceSuccess = (attendance) => ({
  type: CREATE_ATTENDANCE_SUCCESS,
  payload: attendance,
})

export const createAttendanceFailure = (error) => ({
  type: CREATE_ATTENDANCE_FAILURE,
  payload: error,
})

export const updateAttendanceRequest = (attendance) => ({
  type: UPDATE_ATTENDANCE_REQUEST,
  payload: attendance,
})

export const updateAttendanceSuccess = (attendance) => ({
  type: UPDATE_ATTENDANCE_SUCCESS,
  payload: attendance,
})

export const updateAttendanceFailure = (error) => ({
  type: UPDATE_ATTENDANCE_FAILURE,
  payload: error,
})

export const deleteAttendanceRequest = (attendanceId) => ({
  type: DELETE_ATTENDANCE_REQUEST,
  payload: attendanceId,
})

export const deleteAttendanceSuccess = (attendanceId) => ({
  type: DELETE_ATTENDANCE_SUCCESS,
  payload: attendanceId,
})

export const deleteAttendanceFailure = (error) => ({
  type: DELETE_ATTENDANCE_FAILURE,
  payload: error,
})

export const searchAttendancesSuccess = (searchedAttendances) => ({
  type: SEARCH_ATTENDANCES_SUCCESS,
  payload: searchedAttendances,
})

export const searchAttendancesFailure = (error) => ({
  type: SEARCH_ATTENDANCES_FAILURE,
  payload: error,
})
