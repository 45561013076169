import React, { useEffect, useState } from 'react';
import { Table, Spin, Alert, Button, Space, Drawer, Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAdmins, createAdmin, updateAdmin, deleteAdmin } from './admins.api';
import AddAdminForm from './Form/AddAdminForm';

const Admins = () => {
  const dispatch = useDispatch();
  const { loading, admins, error } = useSelector((state) => state.admins);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [currentAdmin, setCurrentAdmin] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(fetchAdmins());
  }, [dispatch]);

  const handleDelete = (id) => {
    dispatch(deleteAdmin(id)).then(() => {
      dispatch(fetchAdmins());
    });
  };

  const handleAddAdmin = async (values) => {
    const adminData = { ...values };
    dispatch(createAdmin(adminData)).then(() => {
      setDrawerVisible(false);
      form.resetFields();
      dispatch(fetchAdmins());
    });
  };

  const handleEditAdmin = async (values) => {
    const adminData = { ...values };
    dispatch(updateAdmin(currentAdmin.id, adminData)).then(() => {
      setDrawerVisible(false);
      setCurrentAdmin(null);
      form.resetFields();
      dispatch(fetchAdmins());
    });
  };

  const handleEditClick = (admin) => {
    const newAdmin = { ...admin };
    newAdmin.role = admin.superAdmin ? 'super_admin' : 'admin';
    newAdmin.first_name = admin.firstName;
    newAdmin.last_name = admin.lastName;
    newAdmin.contact_number = admin.contactNumber;
    newAdmin.campus_ids = admin.campusIds || [];
    setCurrentAdmin(newAdmin);
    form.setFieldsValue(newAdmin);
    setDrawerVisible(true);
  };

  const columns = [
    { title: 'First Name', dataIndex: 'firstName', key: 'firstName' },
    { title: 'Last Name', dataIndex: 'lastName', key: 'lastName' },
    { title: 'Email', dataIndex: 'email', key: 'email' },
    {
      title: 'Super Admin',
      dataIndex: 'superAdmin',
      key: 'superAdmin',
      render: (superAdmin) => (
        <>{superAdmin ? 'Super Admin' : 'Admin'}</>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button type="primary" onClick={() => handleEditClick(record)}>
            Edit
          </Button>
          <Button
            type="danger"
            style={{ backgroundColor: 'red', color: 'white' }}
            onClick={() => handleDelete(record.id)}
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  if (loading) {
    return <Spin tip="Loading admins..." />;
  }

  if (error) {
    return (
      <Alert
        message="Error"
        description={error.message}
        type="error"
        showIcon
      />
    );
  }

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h2>Admins</h2>
        <Button type="primary" onClick={() => {
          setCurrentAdmin(null);
          form.resetFields();
          setDrawerVisible(true);
        }}>
          Add Admin
        </Button>
      </div>
      <Table
        columns={columns}
        dataSource={admins}
        rowKey="id"
        locale={{ emptyText: 'No data' }}
      />

      <Drawer
        title={currentAdmin ? "Edit Admin" : "Add Admin"}
        width={320}
        onClose={() => setDrawerVisible(false)}
        visible={drawerVisible}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <AddAdminForm
          onSubmit={currentAdmin ? handleEditAdmin : handleAddAdmin}
          form={form}
        />
      </Drawer>
    </div>
  );
};

export default Admins;
