// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* AdminSessions.css */

.rbc-event {
    font-size: 14px;
    padding: 4px;
    border-radius: 4px;
    background-color: #357abd;
    color: white;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.rbc-time-slot {
    min-height: 40px; /* Adjust the height as needed */
}

.custom-resource .rbc-event-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.custom-resource .rbc-event-label {
    display: none; /* Hide the default time label */
}

.custom-resource .rbc-day-slot .rbc-time-slot {
    height: 40px; /* Adjust this value to increase the slot height */
}
`, "",{"version":3,"sources":["webpack://./src/components/Sessions/AdminSessions.css"],"names":[],"mappings":"AAAA,sBAAsB;;AAEtB;IACI,eAAe;IACf,YAAY;IACZ,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,gBAAgB;IAChB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB,EAAE,gCAAgC;AACtD;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,aAAa,EAAE,gCAAgC;AACnD;;AAEA;IACI,YAAY,EAAE,kDAAkD;AACpE","sourcesContent":["/* AdminSessions.css */\n\n.rbc-event {\n    font-size: 14px;\n    padding: 4px;\n    border-radius: 4px;\n    background-color: #357abd;\n    color: white;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n}\n\n.rbc-time-slot {\n    min-height: 40px; /* Adjust the height as needed */\n}\n\n.custom-resource .rbc-event-content {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.custom-resource .rbc-event-label {\n    display: none; /* Hide the default time label */\n}\n\n.custom-resource .rbc-day-slot .rbc-time-slot {\n    height: 40px; /* Adjust this value to increase the slot height */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
