import React, { useEffect, useState } from 'react';
import { Table, Spin, Alert, Button, Space, Drawer, Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStudents, createStudent, updateStudent, deleteStudent } from './students.api';
import AddStudentForm from './Form/AddStudentForm';
import QRCode from 'qrcode';

const Students = () => {
  const dispatch = useDispatch();
  const { loading, students, error } = useSelector((state) => state.students);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [currentStudent, setCurrentStudent] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(fetchStudents());
  }, [dispatch]);

  const handleDelete = (id) => {
    dispatch(deleteStudent(id)).then(() => {
      dispatch(fetchStudents());
    });
  };

  const generateQRCode = async (data) => {
    try {
      const qrCodeUrl = await QRCode.toDataURL(JSON.stringify(data));
      return qrCodeUrl;
    } catch (err) {
      console.error('QR Code generation failed:', err);
      return '';
    }
  };

  const handleAddStudent = async (values) => {
    const qrCodeUrl = await generateQRCode(values);
    const studentData = { ...values, qr_code_url: qrCodeUrl };
    dispatch(createStudent(studentData)).then(() => {
      setDrawerVisible(false);
      form.resetFields();
      dispatch(fetchStudents());
    });
  };

  const handleEditStudent = async (values) => {
    const qrCodeUrl = await generateQRCode(values);
    const studentData = { ...values, qr_code_url: qrCodeUrl };
    dispatch(updateStudent(currentStudent.id, studentData)).then(() => {
      setDrawerVisible(false);
      setCurrentStudent(null);
      form.resetFields();
      dispatch(fetchStudents());
    });
  };

  const handleEditClick = (student) => {
    const newStudent = { ...student };
    newStudent.first_name = student.firstName;
    newStudent.last_name = student.lastName;
    newStudent.contact_number = student.contactNumber;
    setCurrentStudent(newStudent);
    form.setFieldsValue(newStudent);
    setDrawerVisible(true);
  };

  const columns = [
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
      sorter: (a, b) => a.firstName.localeCompare(b.firstName),
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
      // sorter: (a, b) => a.lastName.localeCompare(b.lastName),
    },
    { title: 'Campus Name', dataIndex: ['campus', 'name'], key: 'campusName' },
    { title: 'Code', dataIndex: 'code', key: 'code' },
    { title: 'Email', dataIndex: 'email', key: 'email' },
    {
      title: 'QR Code',
      dataIndex: 'qrCodeUrl',
      key: 'qrCodeUrl',
      render: (text, record) => (
        <img src={record.qrCodeUrl} alt="QR Code" style={{ width: '70px' }} />
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button type="primary" onClick={() => handleEditClick(record)}>
            Edit
          </Button>
          <Button
            type="danger"
            style={{ backgroundColor: 'red', color: 'white' }}
            onClick={() => handleDelete(record.id)}
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  if (loading) {
    return <Spin tip="Loading students..." />;
  }

  if (error) {
    return (
      <Alert
        message="Error"
        description={error.message}
        type="error"
        showIcon
      />
    );
  }

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h2>Students</h2>
        <Button type="primary" onClick={() => {
          setCurrentStudent(null);
          form.resetFields();
          setDrawerVisible(true);
        }}>
          Add Student
        </Button>
      </div>
      <Table
        columns={columns}
        dataSource={students}
        rowKey="id"
        locale={{ emptyText: 'No data' }}
      />

      <Drawer
        title={currentStudent ? "Edit Student" : "Add Student"}
        width={320}
        onClose={() => setDrawerVisible(false)}
        visible={drawerVisible}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <AddStudentForm
          onSubmit={currentStudent ? handleEditStudent : handleAddStudent}
          form={form}
        />
      </Drawer>
    </div>
  );
};

export default Students;
