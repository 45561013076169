import {
  FETCH_COURSES_REQUEST,
  FETCH_COURSES_SUCCESS,
  FETCH_COURSES_FAILURE,
  CREATE_COURSE_REQUEST,
  CREATE_COURSE_SUCCESS,
  CREATE_COURSE_FAILURE,
  UPDATE_COURSE_REQUEST,
  UPDATE_COURSE_SUCCESS,
  UPDATE_COURSE_FAILURE,
  DELETE_COURSE_REQUEST,
  DELETE_COURSE_SUCCESS,
  DELETE_COURSE_FAILURE,
} from './courses.actions';

const initialState = {
  courses: [],
  loading: false,
  error: null,
};

const coursesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COURSES_REQUEST:
    case CREATE_COURSE_REQUEST:
    case UPDATE_COURSE_REQUEST:
    case DELETE_COURSE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_COURSES_SUCCESS:
      return {
        ...state,
        loading: false,
        courses: action.payload,
        error: null,
      };
    case CREATE_COURSE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        courses: [...state.courses, action.payload],
      };
    case UPDATE_COURSE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        courses: state.courses.map((course) =>
          course.id === action.payload.id ? action.payload : course,
        ),
      };
    case DELETE_COURSE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        courses: state.courses.filter(
          (course) => course.id !== action.payload,
        ),
      };
    case FETCH_COURSES_FAILURE:
    case CREATE_COURSE_FAILURE:
    case UPDATE_COURSE_FAILURE:
    case DELETE_COURSE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default coursesReducer;
