import request from '../../core/request'
import { openNotification } from '../../utils/Notification'
import {
  fetchStudentsRequest,
  fetchStudentsSuccess,
  fetchStudentsFailure,
  createStudentRequest,
  createStudentSuccess,
  createStudentFailure,
  updateStudentRequest,
  updateStudentSuccess,
  updateStudentFailure,
  deleteStudentRequest,
  deleteStudentSuccess,
  deleteStudentFailure,
  searchStudentsRequest,
  searchStudentsFailure,
  searchStudentsSuccess
} from './students.actions'

// Function to handle fetching students
export const fetchStudents = () => {
  return (dispatch) => {
    dispatch(fetchStudentsRequest())
    return request({
      method: 'GET',
      url: '/students/',
    })
      .then((response) => {
        if (response?.data) {
          dispatch(fetchStudentsSuccess(response.data))
        }  else if (response?.status === 404 || response?.status === 204) {
          dispatch(fetchStudentsSuccess([]))
        }else {
          dispatch(fetchStudentsFailure('Fetching students failed'))
          openNotification({
            type: 'error',
            title: 'Fetching Sessions Failed',
            description: 'Fetching students failed',
          })
        }
      })
      .catch((error) => {
        if(error?.response?.status === 404){
          dispatch(fetchStudentsSuccess([]))
        }
        else {
          dispatch(
            fetchStudentsFailure('An error occurred while fetching students'),
          )
          openNotification({
            type: 'error',
            title: 'Fetching Sessions Error',
            description: `An error occurred while fetching students: ${error.message}`,
          })
        }
      })
  }
}

// Function to handle creating a student
export const createStudent = (studentData) => {
  return (dispatch) => {
    dispatch(createStudentRequest(studentData))
    return request({
      method: 'POST',
      url: '/students/',
      data: studentData,
    })
      .then((response) => {
        if (response?.data) {
          dispatch(createStudentSuccess(response.data))
        } else {
          dispatch(createStudentFailure('Creating student failed'))
          openNotification({
            type: 'error',
            title: 'Creating Student Failed',
            description: 'Creating student failed',
          })
        }
      })
      .catch((error) => {
        dispatch(
          createStudentFailure('An error occurred while creating the student'),
        )
        openNotification({
          type: 'error',
          title: 'Creating Student Error',
          description: `An error occurred while creating the student: ${error.message}`,
        })
      })
  }
}

// Function to handle updating a student
export const updateStudent = (studentId, studentData) => {
  return (dispatch) => {
    dispatch(updateStudentRequest(studentData))
    return request({
      method: 'PUT',
      url: `/students/${studentId}`,
      data: studentData,
    })
      .then((response) => {
        if (response?.data) {
          dispatch(updateStudentSuccess(response.data))
        } else {
          dispatch(updateStudentFailure('Updating student failed'))
          openNotification({
            type: 'error',
            title: 'Updating Student Failed',
            description: 'Updating student failed',
          })
        }
      })
      .catch((error) => {
        dispatch(
          updateStudentFailure('An error occurred while updating the student'),
        )
        openNotification({
          type: 'error',
          title: 'Updating Student Error',
          description: `An error occurred while updating the student: ${error.message}`,
        })
      })
  }
}

// Function to handle deleting a student
export const deleteStudent = (studentId) => {
  return (dispatch) => {
    dispatch(deleteStudentRequest(studentId))
    return request({
      method: 'DELETE',
      url: `/students/${studentId}?deletion_type=hard`,
    })
      .then((response) => {
        if (response?.data) {
          dispatch(deleteStudentSuccess(studentId))
        } else {
          dispatch(deleteStudentFailure('Deleting student failed'))
          openNotification({
            type: 'error',
            title: 'Deleting Student Failed',
            description: 'Deleting student failed',
          })
        }
      })
      .catch((error) => {
        dispatch(
          deleteStudentFailure('An error occurred while deleting the student'),
        )
        openNotification({
          type: 'error',
          title: 'Deleting Student Error',
          description: `An error occurred while deleting the student: ${error.message}`,
        })
      })
  }
}

// Function to handle searching students
export const searchStudents = (query) => {
  return (dispatch) => {
    dispatch(searchStudentsRequest());
    return request({
      method: 'GET',
      url: `/students/?name=${query}`,
    })
      .then((response) => {
        if (response?.data) {
          dispatch(searchStudentsSuccess(response.data));
        } else {
          dispatch(searchStudentsFailure('No students found matching your criteria'));
        }
      })
      .catch((error) => {
        dispatch(searchStudentsFailure('An error occurred while searching for students', error));
      });
  };
};
