import { connect } from 'react-redux'
import Attendance from './Attendance'
import {
  fetchAttendances,
  createAttendance,
  updateAttendance,
  deleteAttendance,
} from './attendance.api'

const mapStateToProps = (state) => ({
  attendances: state.attendances.attendances,
  loading: state.attendances.loading,
  error: state.attendances.error,
})

const mapDispatchToProps = (dispatch) => ({
  fetchAttendances: (sessionId) => dispatch(fetchAttendances(sessionId)),
  createAttendance: (attendanceData) => dispatch(createAttendance(attendanceData)),
  updateAttendance: (attendanceData) => dispatch(updateAttendance(attendanceData)),
  deleteAttendance: (attendanceId) => dispatch(deleteAttendance(attendanceId)),
})

export const AttendanceContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Attendance)
