import { connect } from 'react-redux';
import Admins from './Admins';
import {
  fetchAdmins,
  createAdmin,
  updateAdmin,
  deleteAdmin,
} from './admins.api';

const mapStateToProps = (state) => ({
  admins: state.admins.admins,
  loading: state.admins.loading,
  error: state.admins.error,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAdmins: () => dispatch(fetchAdmins()),
  createAdmin: (adminData) => dispatch(createAdmin(adminData)),
  updateAdmin: (adminData) => dispatch(updateAdmin(adminData)),
  deleteAdmin: (adminId) => dispatch(deleteAdmin(adminId)),
});

export const AdminsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Admins);
