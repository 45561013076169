import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Spin, Collapse, Progress, Row, Col, Pagination } from 'antd';

const { Panel } = Collapse;

const StudentDetails = ({ isModal, visible, onCancel, loading, error, studentDetails }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10; // Number of sessions per page

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const paginatedSessions = studentDetails?.sessions.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const content = (
    <>
      {loading ? (
        <Spin />
      ) : error ? (
        <div className="error-message">{error}</div>
      ) : (
        studentDetails && (
          <div>
            <p><b>Name:</b> {studentDetails.studentName}</p>
            <p><b>Total Sessions:</b> {studentDetails.totalSessions}</p>
            <div style={{ marginBottom: 16 }}>
              <Row>
                <Col span={12}><p><b>Present Percentage:</b></p></Col>
                <Col span={12}>
                  <Progress percent={studentDetails.presentPercentage.toFixed(2)} showInfo={false} style={{ width: '80%' }} />
                  <span> {studentDetails.presentPercentage.toFixed(2)}%</span>
                </Col>
              </Row>
              <Row>
                <Col span={12}><p><b>Absent Percentage:</b></p></Col>
                <Col span={12}>
                  <Progress percent={studentDetails.absentPercentage.toFixed(2)} showInfo={false} status="exception" style={{ width: '80%' }} />
                  <span> {studentDetails.absentPercentage.toFixed(2)}%</span>
                </Col>
              </Row>
              <Row>
                <Col span={12}><p><b>Late Arrival Percentage:</b></p></Col>
                <Col span={12}>
                  <Progress percent={studentDetails.lateArrivalPercentage.toFixed(2)} showInfo={false} style={{ width: '80%' }} />
                  <span> {studentDetails.lateArrivalPercentage.toFixed(2)}%</span>
                </Col>
              </Row>
              <Row>
                <Col span={12}><p><b>Left Early Percentage:</b></p></Col>
                <Col span={12}>
                  <Progress percent={studentDetails.leftEarlyPercentage.toFixed(2)} showInfo={false} style={{ width: '80%' }} />
                  <span> {studentDetails.leftEarlyPercentage.toFixed(2)}%</span>
                </Col>
              </Row>
              <Row>
                <Col span={12}><p><b>Canceled Session Percentage:</b></p></Col>
                <Col span={12}>
                  <Progress percent={studentDetails.canceledSessionPercentage.toFixed(2)} showInfo={false} style={{ width: '80%' }} />
                  <span> {studentDetails.canceledSessionPercentage.toFixed(2)}%</span>
                </Col>
              </Row>
              <Row>
                <Col span={12}><p><b>Informed Absent Percentage:</b></p></Col>
                <Col span={12}>
                  <Progress percent={studentDetails.informedAbsentPercentage.toFixed(2)} showInfo={false} style={{ width: '80%' }} />
                  <span> {studentDetails.informedAbsentPercentage.toFixed(2)}%</span>
                </Col>
              </Row>
              <Row>
                <Col span={12}><p><b>Pending Percentage:</b></p></Col>
                <Col span={12}>
                  <Progress percent={studentDetails.pendingPercentage.toFixed(2)} showInfo={false} style={{ width: '80%' }} />
                  <span> {studentDetails.pendingPercentage.toFixed(2)}%</span>
                </Col>
              </Row>
            </div>
            <h4>Sessions</h4>
            <Collapse>
              {paginatedSessions.map((session) => (
                <Panel header={`${session.sessionName} - ${session.statuses.join(', ')}`} key={session.sessionId}>
                  <p><b>Start:</b> {new Date(session.startDatetime).toLocaleString()}</p>
                  <p><b>End:</b> {new Date(session.endDatetime).toLocaleString()}</p>
                </Panel>
              ))}
            </Collapse>
            <Pagination
              current={currentPage}
              pageSize={pageSize}
              total={studentDetails.sessions.length}
              onChange={handlePageChange}
              style={{ marginTop: 16, textAlign: 'center' }}
            />
          </div>
        )
      )}
    </>
  );

  return isModal ? (
    <Modal
      title={<div style={{ textAlign: 'center', fontSize: 20, fontWeight: 600 }}>Student Details</div>}
      visible={visible}
      onCancel={onCancel}
      footer={null}
      width={'50%'}
    >
      {content}
    </Modal>
  ) : (
    <div style={{ padding: 20 }}>
      <div style={{ textAlign: 'center', fontSize: 20, fontWeight: 600 }}>Your Details</div>
      {content}
    </div>
  );
};

StudentDetails.propTypes = {
  isModal: PropTypes.bool,
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  loading: PropTypes.bool,
  error: PropTypes.string,
  studentDetails: PropTypes.shape({
    studentName: PropTypes.string,
    totalSessions: PropTypes.number,
    presentPercentage: PropTypes.number,
    absentPercentage: PropTypes.number,
    lateArrivalPercentage: PropTypes.number,
    leftEarlyPercentage: PropTypes.number,
    canceledSessionPercentage: PropTypes.number,
    informedAbsentPercentage: PropTypes.number,
    pendingPercentage: PropTypes.number,
    sessions: PropTypes.arrayOf(
      PropTypes.shape({
        sessionId: PropTypes.string,
        sessionName: PropTypes.string,
        startDatetime: PropTypes.string,
        endDatetime: PropTypes.string,
        statuses: PropTypes.arrayOf(PropTypes.string),
      })
    ),
  }),
};

export default StudentDetails;
