import request from '../../core/request'
import { openNotification } from '../../utils/Notification'
import {
  loginSuccess,
  loginFailure,
  logoutSuccess,
} from './auth.actions'
import storage from '../../core/storage'

const saveTokenToLocalStorage = (token, role, studentId, adminId, admin, student) => {
  studentId && localStorage.setItem('studentId', studentId)
  adminId && localStorage.setItem('adminId', adminId)
  adminId && localStorage.setItem('superAdmin', admin.superAdmin)
  localStorage.setItem('token', token)
  admin && localStorage.setItem('admin', JSON.stringify(admin));
  student && localStorage.setItem('student', JSON.stringify(student));
  localStorage.setItem('role', role)
  localStorage.setItem('isAuthenticated', true)
}

// Function to handle login
export const login = (userData) => {
  return (dispatch) => {
      return request({
        method: 'POST',
        url: `/users/login`,
        data: userData,
      })
        .then((response) => {
          if (response?.data) {
            console.log("access_token", response?.data)
            const token = response.data?.accessToken
            const role = response.data?.student ? 'student' : 'admin'
            const studentId = response.data?.student?.id
            const adminId = response.data?.admin?.id
            const admin = response.data?.admin
            const student = response.data?.student
            saveTokenToLocalStorage(token, role, studentId, adminId, admin, student)
            dispatch(loginSuccess(response.data))
          } else {
            dispatch(loginFailure(response?.data?.error || 'Login failed'))
            openNotification({
              type: 'error',
              title: 'Login Failed',
              description: response?.data?.error || 'Login failed',
            })
          }
          return response
        })
        .catch((error) => {
          dispatch(loginFailure('An error occurred during login'))
          openNotification({
            type: 'error',
            title: 'Login Error',
            description: `An error occurred during login: ${error.message}`,
          })
          throw error
        })
  }
}

export const logout = () => (dispatch) => {
  storage.clear()
  dispatch(logoutSuccess())
}
