import { connect } from 'react-redux';
import Courses from './Courses'; // Ensure this path is correct
import {
  fetchCourses,
  createCourse,
  updateCourse,
  deleteCourse,
} from './courses.api';

const mapStateToProps = (state) => ({
  courses: state.courses.courses,
  loading: state.courses.loading,
  error: state.courses.error,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCourses: () => dispatch(fetchCourses()),
  createCourse: (courseData) => dispatch(createCourse(courseData)),
  updateCourse: (courseData) => dispatch(updateCourse(courseData)),
  deleteCourse: (courseId) => dispatch(deleteCourse(courseId)),
});

export const CoursesContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Courses);
