import request from '../../core/request'
import { openNotification } from '../../utils/Notification'
import {
  fetchAttendancesRequest,
  fetchAttendancesSuccess,
  fetchAttendancesFailure,
  createAttendanceRequest,
  createAttendanceSuccess,
  createAttendanceFailure,
  updateAttendanceRequest,
  updateAttendanceSuccess,
  updateAttendanceFailure,
  deleteAttendanceRequest,
  deleteAttendanceSuccess,
  deleteAttendanceFailure,
  searchAttendancesSuccess,
  searchAttendancesFailure,
} from './attendance.actions'

// Function to handle fetching attendances
export const fetchAttendances = (sessionId) => {
  return (dispatch) => {
    dispatch(fetchAttendancesRequest())
    return request({
      method: 'GET',
      url: `/attendance/filter?session_id=${sessionId}`,
    })
      .then((response) => {
        console.log("response", response)
        if (response?.data) {
          dispatch(fetchAttendancesSuccess(response.data))
        } else {
          dispatch(fetchAttendancesFailure('Fetching attendances failed'))
          openNotification({
            type: 'error',
            title: 'Fetching Sessions Failed',
            description: 'Fetching attendances failed',
          })
        }
      })
      .catch((error) => {
        if(error?.response?.status === 404){
          dispatch(fetchAttendancesSuccess([]))
        }
        else {
          dispatch(
            fetchAttendancesFailure('An error occurred while fetching attendances'),
          )
          openNotification({
            type: 'error',
            title: 'Fetching Sessions Error',
            description: `An error occurred while fetching attendances: ${error.message}`,
          })
        }
      })
  }
}

// Function to handle creating a attendance
export const createAttendance = (attendanceData) => {
  return (dispatch) => {
    dispatch(createAttendanceRequest(attendanceData))
    return request({
      method: 'POST',
      url: '/attendances',
      data: attendanceData,
    })
      .then((response) => {
        if (response?.data) {
          dispatch(createAttendanceSuccess(response.data))
        } else {
          dispatch(createAttendanceFailure('Creating attendance failed'))
          openNotification({
            type: 'error',
            title: 'Creating Attendance Failed',
            description: 'Creating attendance failed',
          })
        }
      })
      .catch((error) => {
        dispatch(
          createAttendanceFailure('An error occurred while creating the attendance'),
        )
        openNotification({
          type: 'error',
          title: 'Creating Attendance Error',
          description: `An error occurred while creating the attendance: ${error.message}`,
        })
      })
  }
}

// Function to handle updating a attendance
export const updateAttendance = (attendanceData) => {
  return (dispatch) => {
    dispatch(updateAttendanceRequest(attendanceData))
    return request({
      method: 'PUT',
      url: `/attendances/${attendanceData.id}`,
      data: attendanceData,
    })
      .then((response) => {
        if (response?.data) {
          dispatch(updateAttendanceSuccess(response.data))
        } else {
          dispatch(updateAttendanceFailure('Updating attendance failed'))
          openNotification({
            type: 'error',
            title: 'Updating Attendance Failed',
            description: 'Updating attendance failed',
          })
        }
      })
      .catch((error) => {
        dispatch(
          updateAttendanceFailure('An error occurred while updating the attendance'),
        )
        openNotification({
          type: 'error',
          title: 'Updating Attendance Error',
          description: `An error occurred while updating the attendance: ${error.message}`,
        })
      })
  }
}

// Function to handle deleting a attendance
export const deleteAttendance = (attendanceId) => {
  return (dispatch) => {
    dispatch(deleteAttendanceRequest(attendanceId))
    return request({
      method: 'DELETE',
      url: `/attendances/${attendanceId}`,
    })
      .then((response) => {
        if (response?.data) {
          dispatch(deleteAttendanceSuccess(attendanceId))
        } else {
          dispatch(deleteAttendanceFailure('Deleting attendance failed'))
          openNotification({
            type: 'error',
            title: 'Deleting Attendance Failed',
            description: 'Deleting attendance failed',
          })
        }
      })
      .catch((error) => {
        dispatch(
          deleteAttendanceFailure('An error occurred while deleting the attendance'),
        )
        openNotification({
          type: 'error',
          title: 'Deleting Attendance Error',
          description: `An error occurred while deleting the attendance: ${error.message}`,
        })
      })
  }
}

// Function to handle searching attendances
export const searchAttendances = (query) => {
  return (dispatch) => {
    return request({
      method: 'GET',
      url: `/attendances/search?query=${query}`,
    })
      .then((response) => {
        if (response?.data) {
          dispatch(searchAttendancesSuccess(response.data))
        } else {
          dispatch(searchAttendancesFailure('Searching attendance failed'))
          openNotification({
            type: 'error',
            title: 'Searching Sessions Failed',
            description: 'Searching attendances failed',
          })
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          // Handle 404 (not found) error
          dispatch(searchAttendancesFailure('Searching attendance failed'))
        } else {
          openNotification({
            type: 'error',
            title: 'Searching Sessions Error',
            description: `An error occurred while searching attendances: ${error.message}`,
          })
        }
      })
  }
}
