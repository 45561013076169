import React from 'react';
import { Tooltip } from 'antd';
import PropTypes from 'prop-types';

const EventComponent = ({ event }) => {
  const formattedNames = event.extendedProps.studentNames.join(', ');

  return (
    <Tooltip
      title={(
        <div>
          <div><strong>Session:</strong> {event.title.split(' (')[0]}</div>
          <div><strong>Capacity:</strong> {event.extendedProps.capacity}</div>
          <div><strong>Course:</strong> {event.extendedProps.courseName}</div>
          <div><strong>Students:</strong> {formattedNames}</div>
        </div>
      )}
    >
      <div title={event.title}
           style={{ width: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
        {event.title}
      </div>
    </Tooltip>
  );
};

EventComponent.propTypes = {
  event: PropTypes.shape({
    title: PropTypes.string.isRequired,
    extendedProps: PropTypes.shape({
      capacity: PropTypes.number.isRequired,
      studentNames: PropTypes.arrayOf(PropTypes.string).isRequired,
      courseName: PropTypes.string,
      campus_id: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default EventComponent;
